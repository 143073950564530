import { IconButton, useClipboard, useToast } from "@chakra-ui/react";
import React from "react";

import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

const CopyableText = ({ text }) => {
  const clipboard = useClipboard(text);
  const toast = useToast();
  // const label = clipboard.hasCopied ? "Copied!" : "Copy to Clipboard";

  const handleClick = () => {
    clipboard.onCopy();
    toast({ status: "info", description: "Copied to clipboard!", icon: null });
  };

  return (
    <Text
      display="inline-flex"
      alignItems="center"
      alignContent="center"
      textStyle="context"
      mb="0"
    >
      <Text isTruncated fontSize="1em" maxWidth="12em" mb="0">
        {text}
      </Text>
      <IconButton
        onClick={handleClick}
        variant="none"
        icon={<Icon.Copy size=".8em" />}
      />
    </Text>
  );
};

export default CopyableText;
