import { VStack } from "@chakra-ui/react";
import { InvestmentSignatureBox } from "./InvestmentSignatureBox";

const InvestmentSignatureList = ({ investmentId, investors }) => {
  return (
    <VStack as="ul" gap="4" p={0} maxWidth="full">
      {investors?.map((signature) => (
        <InvestmentSignatureBox
          key={signature.signature_id}
          investmentId={investmentId}
          signature={signature}
        />
      ))}
    </VStack>
  );
};

export default InvestmentSignatureList;
