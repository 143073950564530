import { useMutation, useQuery } from "@tanstack/react-query";

import { useApi } from "@equidefi/portals/hooks/useApi";

import PaymentClient from "@equidefi/portals/clients/PaymentClient";
import InvestmentClient from "../clients/InvestmentClient";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInvestment = (id, options = {}) => {
  return useQuery(
    ["investment", id],
    () => InvestmentClient.get(id),
    options ?? {}
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentAddRefund = (id, options = {}) => {
  const paymentsApi = useApi(PaymentClient);

  return useMutation(
    ({ data }) => paymentsApi.addManualRefund({ investment_id: id, ...data }),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentAddManualPayment = (id, options = {}) => {
  const paymentsApi = useApi(PaymentClient);

  return useMutation(
    ({ data }) => paymentsApi.addManual({ investment_id: id, ...data }),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentUpdate = (id, options = {}) => {
  const investmentApi = useApi(InvestmentClient);

  return useMutation((data = {}) => investmentApi.update(id, data), options);
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInvestmentInquiry = (id, options = {}) => {
  return useQuery(
    ["investment", id, "inquiry"],
    () => InvestmentClient.getInquiry(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */

export const useInvestmentAgreementRecipients = (id, options = {}) => {
  const investmentApi = useApi(InvestmentClient);

  return useQuery(
    ["investment", id, "agreements"],
    () => investmentApi.getAgreements(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }
  );
};
