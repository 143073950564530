import clsx from "clsx";
import { Badge, Button } from "@chakra-ui/react";
import styles from './InvestmentAccreditation.module.css';

import { offeringIsRule506c } from "@equidefi/shared";
import {useState} from "react";
import AccreditationUpload from "./AccreditationUpload";
import {isAdmin, isIssuerOwner} from "../../../helpers";

const AccreditationStatusBadge = ({ accredited = null }) => {
  const isUnknown = typeof accredited !== 'boolean';
  const isAccredited = accredited === true;
  const isNotAccredited = accredited === false;

  return (
    <Badge
      colorScheme={clsx({
        green: isAccredited,
        gray: isUnknown,
        orange: isNotAccredited,
      })}
    >
      {isAccredited && "Accredited"}
      {isNotAccredited && "Not Accredited"}
      {isUnknown && "Not Reported"}
    </Badge>
  );
};


const AccreditationIncomeBadge = ({ income = null }) => {
  return (
    <Badge colorScheme={income ? "green" : "gray"}>
      {income ? "Encrypted" : "Not Reported"}
    </Badge>
  );
};


const AccreditationListItem = ({ title, children }) => {
  return (
    <div className="list-group-item">
      <div className="d-flex align-content-center align-items-center justify-content-between">
        <span>{title}</span>
        {children}
      </div>
    </div>
  );
};


const UploadModal = ({investment, isOpen, onClose}) => {

  if (!isOpen) return null;

  return (
    <AccreditationUpload
      isOpen={true}
      onClose={onClose}
      investment={investment}
    />
  )
}

const InvestmentAccreditation = ({investment, offering, user}) => {
  const [isOpen, setOpen] = useState(false);

  if (!investment) return null;

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Investment Accreditation</h4>
      </div>
      <div className="card-body py-2">
        <div className="list-group list-group-flush">
          <AccreditationListItem title="Accreditation Status">
            <AccreditationStatusBadge accredited={investment.accredited} />
          </AccreditationListItem>

          {!offeringIsRule506c(offering) && (
            <AccreditationListItem title="Annual Income Reported">
              <AccreditationIncomeBadge income={investment.annual_income} />
            </AccreditationListItem>
          )}
        </div>
      </div>
      {(isAdmin(user) || isIssuerOwner(user)) && (
        <>
          <div className={styles.uploadOverride}>
            <Button
              size="sm"
              onClick={() => setOpen(true)}
            >
              Upload Files for Investor
            </Button>
          </div>
          <UploadModal
            investment={investment}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          />
        </>
    )}
    </div>
  );
};

export default InvestmentAccreditation;
