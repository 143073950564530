import { sortBy, uniqBy } from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import NumberFormat from "react-number-format";

import { Badge, Button, ButtonGroup, IconButton } from "@chakra-ui/react";
import { hasEvent } from "@equidefi/portals/helpers/investments";
import { Icon } from "@equidefi/ui/icon";

import ManualPaymentModal from "./ManualPaymentModal";
import ManualRefundModal from "./ManualRefundModal";

const PAYMENT_BADGE_MAPS = {
  created: { color: "blue", text: "Payment Pending" },
  requires_action: { color: "gray", text: "Requires Action" },
  requires_payment_method: {
    color: "gray",
    text: "Requires Payment Method",
  },
  processing: { color: "green", text: "Payment Processing" },
  succeeded: { color: "green", text: "Payment Successful" },
  payment_failed: { color: "red", text: "Payment Failed" },
  canceled: { bg: "orange", text: "Canceled" },
  manual_payment_succeeded: { color: "green", text: "Manual Payment" },
  manual_refund_succeeded: { color: "orange", text: "Manual Refund" },
};

function stripePaymentUrl(paymentIntentId, stripeAccountId) {
  const base = new URL("https://dashboard.stripe.com");

  const prefix = stripeAccountId ? `connect/accounts/${stripeAccountId}` : ``;

  return new URL(`${prefix}/payments/${paymentIntentId}`, base);
}

const PaymentRow = ({ payment, offering }) => {
  const badgeConfig = PAYMENT_BADGE_MAPS[payment.status];

  return (
    <tr key={payment.id}>
      <td className="align-baseline">
        {payment.payment_intent_id ? "Stripe" : "Manual"}
      </td>
      <td className="align-baseline">
        {payment.create_date &&
          moment(payment.create_date).format("MMM DD, YYYY h:mma ZZ")}
      </td>
      <td className="text-center align-baseline">
        {badgeConfig && (
          <Badge fontSize="0.65em" colorScheme={badgeConfig.color ?? "gray"}>
            {badgeConfig.text}
          </Badge>
        )}
      </td>
      <td className="text-end align-baseline">
        <NumberFormat
          value={payment.amount}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale={2}
          thousandSeparator
          prefix="$"
        />
      </td>
      <td className="align-baseline">
        {payment.payment_intent_id ? (
          <IconButton
            as="a"
            icon={<Icon.ExternalLink size="1em" />}
            colorScheme="gray"
            size="xs"
            aria-label="Go to payment on Stripe"
            href={stripePaymentUrl(
              payment.payment_intent_id,
              offering?.stripe_account_id
            )}
            target="_blank"
            rel="noreferrer noopener"
          >
            Go
          </IconButton>
        ) : null}
      </td>
    </tr>
  );
};

const InvestmentPayments = ({
  investment,
  offering,
  canEdit = false,
  totalPaid = 0,
  totalBalance = 0,
  onUpdatePayments = () => {},
}) => {
  const [modalPayment, showPaymentModal] = useState(false);
  const [modalRefund, showRefundModal] = useState(false);

  const isInvestmentCompleted = useMemo(() => {
    return (
      hasEvent(investment?.event_history, "COUNTERSIGN", "COMPLETED") ||
      hasEvent(investment?.event_history, "CLOSING", "COMPLETED")
    );
  }, [investment?.event_history]);

  const canAddPayment = useMemo(() => {
    return totalBalance > 0 && !isInvestmentCompleted;
  }, [totalBalance, isInvestmentCompleted]);

  const canAddRefund = useMemo(() => {
    return totalPaid > 0 && !isInvestmentCompleted;
  }, [totalPaid, isInvestmentCompleted]);

  const payments = useMemo(
    () =>
      sortBy(
        uniqBy(
          [
            ...(investment?.stripe_payments ?? []),
            ...(investment?.payments ?? []),
          ],
          "id"
        ),
        (p) => moment(p.create_date)
      ),
    [investment]
  );

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">Payments</h4>

          {offering?.status === "Active" && canEdit && (
            <ButtonGroup size="xs" colorScheme="gray">
              {canAddPayment && (
                <Button onClick={() => showPaymentModal(true)}>
                  Add Manual Payment
                </Button>
              )}
              {canAddRefund && (
                <Button onClick={() => showRefundModal(true)}>
                  Add Manual Refund
                </Button>
              )}
            </ButtonGroup>
          )}
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Type</th>
              <th>Date</th>
              <th className="text-center">Status</th>
              <th className="text-end">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <PaymentRow
                payment={payment}
                offering={offering}
                key={payment.id}
              />
            ))}
          </tbody>
        </table>
      </div>

      {createPortal(
        <ManualRefundModal
          isVisible={investment?.id && modalRefund}
          investmentId={investment?.id}
          totalPaid={totalPaid}
          onHide={() => showRefundModal(false)}
          onSubmit={() => onUpdatePayments?.()}
        />,
        document.body
      )}
      {createPortal(
        <ManualPaymentModal
          isVisible={investment?.id && modalPayment}
          investmentId={investment?.id}
          totalBalance={totalBalance}
          onHide={() => showPaymentModal(false)}
          onSubmit={() => onUpdatePayments?.()}
        />,
        document.body
      )}
    </>
  );
};

export default InvestmentPayments;
