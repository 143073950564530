import { ApiClient } from "./ApiClient";

class AgreementClient extends ApiClient {
  async templates(search?: string) {
    const response = await this.get("/v2/agreements/templates", {
      params: { search },
    });
    return response.data;
  }

  async template(id?: string) {
    const response = await this.get(`/v2/agreements/templates/${id}`);
    return response.data;
  }

  async createRequest(investmentId: string) {
    const response = await this.post(`/v2/agreements/signatures/request`, {
      investment_id: investmentId,
    });
    return response.data;
  }

  async embeddedSignatureUrl(
    investmentAgreementId: string,
    signatureId: string
  ) {
    const response = await this.get(
      `/v2/agreements/signers/${investmentAgreementId}/${signatureId}/embedded-url`
    );
    return response.data;
  }

  async downloadAgreement(id: string) {
    const response = await this.get(
      `/v2/agreements/investments/${id}/download`
    );
    return response.data;
  }

  async offeringAgreements(id: string) {
    const response = await this.get(`/v2/agreements/offerings/${id}`);
    return response.data;
  }

  async deleteOfferingAgreement(id: string, agreementId: string) {
    const response = await this.delete(
      `/v2/agreements/offerings/${id}/${agreementId}`
    );
    return response.data;
  }

  async createOfferingAgreement(id: string, data: Record<string, any>) {
    const response = await this.post(`/v2/agreements/offerings/${id}`, data);
    return response.data;
  }

  async updateOfferingAgreement(
    id: string,
    offeringAgreementId: string,
    data: Record<string, any>
  ) {
    const response = await this.patch(
      `/v2/agreements/offerings/${id}/agreements/${offeringAgreementId}`,
      data
    );
    return response.data;
  }
  async investmentSignatures(id: string) {
    const response = await this.get(`/v2/agreements/investments/${id}`);
    return response.data;
  }

  async pingNextSecondarySigner(id: string) {
    const response = await this.post(`/v2/agreements/investments/${id}/ping`);
    return response.data;
  }

  async cancelAgreement(
    investmentId: string,
    investmentAgreementId: string,
    reason: string
  ) {
    const response = await this.delete(
      `/v2/agreements/investments/${investmentId}/${investmentAgreementId}`,
      { data: { reason } }
    );

    return response.data;
  }

  async addSigners(id: string, signers: unknown) {
    const response = await this.post(`/v2/agreements/signers/${id}`, {
      signers,
    });
    return response.data;
  }

  async additionalSignerDetails(
    investmentAgreementId: string,
    signatureId: string
  ) {
    const response = await this.get(
      `/v2/agreements/signers/${investmentAgreementId}/${signatureId}`
    );

    return response.data;
  }
}

export default AgreementClient;
