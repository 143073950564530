/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { parsePhoneNumber } from "react-phone-number-input";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert";
import { StringParam, useQueryParam } from "use-query-params";
import * as Yup from "yup";

import
  {
    Badge,
    Box,
    Button,
    Link as CLink,
    Tooltip as CTooltip,
    Flex,
    IconButton,
    LinkBox,
    LinkOverlay,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Stack,
    Tag,
    Text,
    useToast,
    useToken,
  } from "@chakra-ui/react";
import
  {
    EventBadge,
    StatusBadge,
  } from "@equidefi/portals/components/investments/StatusBadge";
import { hasEvent } from "@equidefi/portals/helpers/investments";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { userFullName } from "@equidefi/shared";
import { Icon } from "@equidefi/ui/icon";
import { capitalize } from "lodash";
import InvestmentClient from "../../clients/InvestmentClient";
import SearchBar from "../../components/SearchBar";
import InfoCard from "../../components/card";
import Spinner from "../../components/spinner";
import { isAdmin, isOfferingEditor, isOfferingOwner } from "../../helpers";
import
  {
    useOfferingFull,
    useOfferingInvestments,
    useOfferingSettings,
  } from "../../hooks/useOfferings";
import AppHeader from "../../layouts/header";

const WorkflowEventList = [
  "REGISTRATION",
  "SUBSCRIPTION",
  "PAYMENT",
  "PROFILE",
  "ADDRESS",
  "SIGNERS",
  "KYC",
  "AGREEMENT",
  "ACCREDITATION",
  "DILIGENCE",
  "COUNTERSIGN",
  "CLOSING",
];

const isFunded = (investment) => {
  let value = 0;
  investment.stripe_payments?.forEach((payment) => {
    if (payment?.status?.includes("succeeded")) value += Number(payment.amount);
  });
  investment.payments?.forEach((payment) => {
    if (payment?.status?.includes("succeeded")) value += Number(payment.amount);
  });

  if (value === 0) return false;

  return (
    value === Number(investment.approved_amount) ||
    value === Number(investment.amount)
  );
};

export const AccountType = ({
  label = "Are you investing as an individual or an entity? (Corp, LLC, Trust etc.)",
  handleChange,
  handleBlur,
  errors,
  values,
}) => {
  return (
    <div className="form-group account-type">
      <label>{label}</label>
      <div>
        <label className="form-check-label me-2" htmlFor="entity_type">
          Individual/Joint
        </label>
        <input
          className="form-check-input me-4"
          name="entity_type"
          type="radio"
          value="Individual"
          checked={values?.entity_type?.toUpperCase() === "INDIVIDUAL"}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <label className="form-check-label me-2" htmlFor="entity_type">
          Entity
        </label>
        <input
          className="form-check-input"
          name="entity_type"
          type="radio"
          value="Entity"
          checked={values?.entity_type?.toUpperCase() === "ENTITY"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <i
          className="fe fe-info ms-2"
          data-tooltip-id="tooltip"
          data-tooltip-html="<strong>List of Entity Types:</strong><br />
            Corporation<br />
            Limited Liability Corporation (LLC)<br />
            Limited Liability Partnership (LLP)<br />
            Limited Partnership (LP)<br />
            Registered Investment Company<br />
            Revocable Trust<br />
            Irrevocable Trust<br />
            Testamentary Trust<br />
            Community Property<br />
            Tenants in Common<br />
            Rights of Survivorship<br />
            Foreign Joint Account<br />
            Self-Directed IRA"
          data-tooltip-place="right"
        />
        <Tooltip id="tooltip" style={{ zIndex: 1000, maxWidth: "50vw" }} />
      </div>
      {errors?.entity_type && (
        <span className="text-danger">{errors?.entity_type}</span>
      )}
    </div>
  );
};

const Investors = () => {
  const { id } = useParams();
  const [selectedItems, setSelectedItems] = useState([]);

  const [green] = useToken("colors", ["green.600"]);

  const [spinner, showSpinner] = useState(false);
  const [closeBtnLoader, setCloseBtnLoader] = useState(false);

  const [reconModal, showReconModal] = useState(false);

  const [filter, setFilter] = useQueryParam("fitler", StringParam);
  const [filterStep, setFilterStep] = useQueryParam("current", StringParam);
  // const [filter, setFilter] = useState("");
  // const [filterStep, setFilterStep] = useState("");
  const [bulkEdit, setBulkEdit] = useState(false);

  const { data: me } = useCurrentUser();

  const [bulkEditValues, setBulkEditValues] = useState([]);
  const [completeStatus, setCompleteStatus] = useState(false);

  const [searchPhrase, setSearchPhrase] = useState(null);

  const toast = useToast();

  const investmentApi = useApi(InvestmentClient);

  const { data } = useOfferingFull(id);
  const { offering } = data ?? {};
  const { data: settings } = useOfferingSettings(id);
  const { data: investments, refetch: getData } = useOfferingInvestments(
    id,
    searchPhrase
  );

  const offeringSettings = useMemo(() => {
    if (!settings) return new Map();

    return settings.reduce((map, setting) => {
      map.set(setting.id, setting.value);
      return map;
    }, new Map());
  }, [settings]);

  const isAuthorizedSignatory = useMemo(
    () => offering?.signatory && offering?.signatory?.email === me?.email,
    [offering?.signatory, me?.email]
  );

  useEffect(() => {
    if (selectedItems.length > 0) {
      const arr = [];
      selectedItems.forEach((id) => {
        const filter = investments.filter((x) => x.id === id);
        const data = {
          id: filter[0]?.id,
          approved_amount: filter[0]?.approved_amount,
          approved_quantity: filter[0]?.approved_quantity,
        };
        arr.push(data);
      });
      setBulkEditValues(arr);
    } else {
      setBulkEdit(false);
    }

    if (bulkEditValues.length > 0) {
      bulkEditValues.forEach((item) => {
        if (!selectedItems.includes(item.id)) {
          let index = investments.findIndex((x) => x.id === item.id);
          investments[index].approved_amount = item.approved_amount;
          investments[index].approved_quantity = item.approved_quantity;
          investments[index].edited = false;
        }
      });
    }

    closeAllSetStatus();
  }, [selectedItems]);

  const closeAllSetStatus = () => {
    let arr = [];
    if (selectedItems?.length > 0) {
      setCloseBtnLoader(true);
      investments?.forEach((investment) => {
        selectedItems?.forEach((AcceptedId) => {
          if (investment?.id === AcceptedId) {
            if (investment?.agreements) {
              axios
                .get(
                  `investment/${AcceptedId}/agreement/${investment?.agreements[0]?.envelope_id}/recipients`
                )
                .then((res) => {
                  res.data.forEach((status) => {
                    if (status.status === "completed") {
                      arr.push(true);
                    } else {
                      arr.push(false);
                    }
                  });
                  arr.includes(false)
                    ? setCompleteStatus(false)
                    : setCompleteStatus(true);
                  setCloseBtnLoader(false);
                });
            }
          }
        });
      });
    } else {
      setCompleteStatus(false);
    }
  };

  const summary = useMemo(() => {
    const _data = {
      total: offering?.total_amount ?? 0,
      remaining_amount: data?.summary?.remaining_amount ?? 0,
      remaining_units: data?.summary?.remaining_units ?? 0,
      paid: 0,
      closed: 0,
      accepted: 0,
    };

    if (!offering || !investments) return;

    for (const investment of investments) {
      if (hasEvent(investment.event_history, "CLOSING", "COMPLETED")) {
        _data.closed += parseFloat(investment.amount);
        continue;
      }

      if (hasEvent(investment.event_history, "COUNTERSIGN", "COMPLETED")) {
        _data.accepted += parseFloat(investment.amount);
        continue;
      }

      if (hasEvent(investment.event_history, "PAYMENT", "APPROVED")) {
        const amount = investment.approved_amount
          ? investment.approved_amount
          : investment.amount;
        _data.paid += parseFloat(amount);
        continue;
      }
    }

    _data.progress =
      _data.total === 0 ? 0 : parseFloat((_data.closed * 100) / _data.total);

    _data.allow_closing =
      _data.paid + _data.accepted + _data.closed >
      offering.minimum_units * offering.unit_price;

    return _data;
  }, [offering, data, investments]);

  const reconInitialValues = {
    reason: "",
    recon_amount: offering?.total_amount,
    recon_unit_price: offering?.unit_price,
  };

  const reconSchema = Yup.object().shape({
    reason: Yup.string().required("Required"),
    recon_amount: Yup.number().required("Required"),
    recon_unit_price: Yup.number().required("Required."),
  });

  const handleCheckChange = (e, id) => {
    let items = [...selectedItems];
    if (e.target.checked) {
      items.push(id);
      setSelectedItems(items);
    } else {
      items = items.filter((x) => x !== id);
      setSelectedItems(items);
    }
  };

  const handleAcceptAll = async () => {
    const status = await Swal({
      title: `Accept Investments?`,
      text: `You will be redirected to DocuSign to countersign the pending investments, do you want to continue?`,
      buttons: ["Cancel", "Yes"],
      icon: "success",
      dangerMode: true,
    });

    if (status) {
      showSpinner(true);

      if (selectedItems.length === 0)
        investments
          ?.filter((x) => x.status === filter)
          ?.forEach((item) => selectedItems.push(item.id));
      const items = investments.filter((x) => selectedItems.includes(x.id));

      for (const investment of items) {
        const agreement = investment.agreements?.filter(
          (x) =>
            x.investor_status === "complete" && x.issuer_status !== "complete"
        );

        if (agreement?.length > 0) {
          try {
            const response = await axios.get(
              `investment/${investment.id}/agreement/${agreement[0].envelope_id}/url/issuer`
            );
            window.open(response.data.url, "_blank");
          } catch (error) {
            console.log(error);
          }
        }
      }

      showSpinner(false);
    }
  };

  const handleCloseAll = () => {
    Swal({
      title: `Close Investments?`,
      text: `The closing letter for all accepted investors will be sent to the Transer Agent for closing, do you want to continue?`,
      buttons: ["Cancel", "Yes"],
      icon: "success",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        let amount = 0;
        if (selectedItems.length === 0)
          investments
            .filter((x) => x.status === filter)
            .forEach((item) => selectedItems.push(item.id));
        selectedItems.forEach((item) => {
          const total = investments.filter((x) => x.id === item);
          amount += total[0].amount;
        });

        const bankType = offeringSettings.get("payment_bank_type");
        if (["escrow", "escrow est"].includes(bankType.toLowerCase())) {
          toast({
            status: "success",
            description: `The funds in the amount of $${amount.toLocaleString()} will be wired to your bank account within 24 hours.`,
          });
        }

        axios.post(`investment/close`, selectedItems).then((response) => {
          getData();
          setFilter("");
          setSelectedItems([]);
        });
      }
    });
  };

  const handleBulkEdit = (id, value) => {
    let index = investments.findIndex((x) => x.id === id);
    investments[index].approved_amount = value;
    investments[index].approved_quantity = value / offering.unit_price;
    investments[index].edited = true;
    // setInvestments(Object.create(investments));
  };

  const handleAcceptBulkEdit = async () => {
    showSpinner(true);
    let items = investments.filter((x) => x.status === filter);
    for (let i in items) {
      if (items[i].edited)
        await axios.post(
          `investment/${items[i].id}/approved_quantity/${items[i].approved_quantity}`
        );
      else if (items[i].approved_quantity === undefined)
        await axios.post(
          `investment/${items[i].id}/approved_quantity/${items[i].quantity}`
        );
    }

    showSpinner(false);
    setBulkEdit(false);
  };

  const rejectInvestor = async (id) => {
    const status = await Swal({
      title: `Reject`,
      text: `Are you sure you want to reject this investor?`,
      buttons: ["Cancel", "Reject"],
      icon: "warning",
      dangerMode: true,
    });

    if (status) {
      await investmentApi.reject(id);
      getData();
      toast({ status: "success", description: "Investor has been deleted" });
    }
  };

  const deleteAllInvestor = async (id, index) => {
    Swal({
      title: `Delete`,
      text: `Are you sure you want to delete it?`,
      buttons: ["Cancel", "Delete"],
      icon: "warning",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        let temp = [...investments];
        if (selectedItems.length > 0) {
          selectedItems.forEach((item, key) => {
            temp.forEach((i) => {
              if (i.id === item) {
                const ind = temp.indexOf(i);
                axios
                  .delete(`/investment/${item}`)
                  .then((res) => {
                    temp.splice(ind, 1);
                    getData();
                    setSelectedItems([]);
                  })
                  .catch(() => {
                    toast({
                      status: "error",
                      description:
                        "Something went wrong, please try again later.",
                    });
                  });
              }
            });
            if (selectedItems.length - 1 === key) {
              toast({
                status: "success",
                description: "Investors has been deleted",
              });
            }
          });
        } else if (id) {
          axios.delete(`/investment/${id}`).then((res) => {
            getData();
            toast({
              status: "success",
              description: "Investor has been deleted",
            });
          });
        }
      }
    });
  };

  const canEdit = useMemo(
    () =>
      isAdmin(me) ||
      isOfferingEditor(me, offering?.id) ||
      isOfferingOwner(me, offering?.id),
    [me, offering?.id]
  );

  const matchedInvestments = useMemo(() => {
    return (
      investments
        ?.filter((inv) => {
          return filter ? inv.status === filter : true;
        })
        ?.filter((inv) => {
          return filterStep ? inv.next === filterStep : true;
        }) ?? []
    );
  }, [investments, filter, filterStep]);

  const anyMatchedInvestments = matchedInvestments.length > 0;

  if (!investments || !offering) {
    return <Spinner show />;
  }

  return (
    <>
      <AppHeader id={id} name="Investors" title={offering.name} />

      <div className="container-fluid">
        <Spinner show={spinner}>
          {summary.progress > 0 && (
            <div id="progress" className="progress" style={{ height: 24 }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${summary.progress}%` }}
              >
                {parseInt(summary.progress)}%
              </div>
            </div>
          )}

          <div id="summary">
            <div className="row g-3 mt-3">
              <InfoCard title="Maximum Offering">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.total}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Total Funds Received">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.paid + summary.accepted + summary.closed}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Funds Accepted">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.accepted + summary.closed}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
            </div>
            <div className="row g-3">
              <InfoCard title="Funds Available for Closing">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.accepted}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Closed funds">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.closed}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Balance Available">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.remaining_amount}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Available shares">
                <NumberFormat
                  displayType="text"
                  thousandSeparator
                  value={summary.remaining_units}
                  decimalScale={0}
                />
              </InfoCard>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-header">
              <Flex gap="2">
                <SearchBar
                  defaultSearchPhrase={searchPhrase}
                  onClear={() => setSearchPhrase("")}
                  onSearch={(phrase) => setSearchPhrase(phrase)}
                  placeholder="Search Investors..."
                />
                <Menu>
                  <MenuButton
                    rightIcon={<Icon.ChevronDown size="1em" />}
                    size="sm"
                    as={Button}
                    colorScheme="gray"
                    textTransform="capitalize"
                    minWidth="auto"
                  >
                    Status: {filter ? filter.toLocaleLowerCase() : "Any"}
                  </MenuButton>
                  <MenuList zIndex="dropdown">
                    <MenuOptionGroup defaultValue="">
                      <MenuItemOption onClick={() => setFilter("")} value="">
                        Any
                      </MenuItemOption>
                      {[
                        "PENDING",
                        "REVIEW",
                        "APPROVED",
                        "COMPLETED",
                        "REJECTED",
                      ].map((evt) => {
                        return (
                          <MenuItemOption
                            key={evt}
                            onClick={() => setFilter(evt)}
                            value={evt}
                            textTransform="capitalize"
                          >
                            {evt.toLowerCase()}
                          </MenuItemOption>
                        );
                      })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>

                <Menu>
                  <MenuButton
                    rightIcon={<Icon.ChevronDown size="1em" />}
                    size="sm"
                    as={Button}
                    colorScheme="gray"
                    textTransform="capitalize"
                    minWidth="auto"
                  >
                    Current Step:{" "}
                    {filterStep ? filterStep.toLocaleLowerCase() : "Any"}
                  </MenuButton>
                  <MenuList rootProps={{ maxH: "100px" }} zIndex="dropdown">
                    <MenuOptionGroup defaultValue="">
                      <MenuItemOption
                        onClick={() => setFilterStep("")}
                        value=""
                      >
                        Any
                      </MenuItemOption>
                      {WorkflowEventList.map((evt) => {
                        return (
                          <MenuItemOption
                            key={evt}
                            onClick={() => setFilterStep(evt)}
                            value={evt}
                            textTransform="capitalize"
                          >
                            {evt.toLowerCase()}
                          </MenuItemOption>
                        );
                      })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Flex>
              {offering.status === "Active" && (
                <div id="actions">
                  {!bulkEdit &&
                    filter === "Payment Successful" &&
                    isAdmin(me) &&
                    anyMatchedInvestments && (
                      <button
                        className="btn btn-light btn-sm ms-2"
                        onClick={() => setBulkEdit(true)}
                        {...(isAuthorizedSignatory && selectedItems.length
                          ? { disabled: false }
                          : { disabled: true })}
                      >
                        <i className="fe fe-edit-2" /> Bulk Edit
                      </button>
                    )}

                  {!bulkEdit &&
                    filter === "Payment Successful" &&
                    anyMatchedInvestments && (
                      <button
                        className="btn btn-eq-primary btn-sm ms-2"
                        onClick={() => handleAcceptAll()}
                        {...(isAuthorizedSignatory && selectedItems.length
                          ? { disabled: false }
                          : { disabled: true })}
                      >
                        <i className="fe fe-check-circle"></i> Accept All
                      </button>
                    )}

                  {bulkEdit && filter === "Payment Successful" && canEdit && (
                    <button
                      className="btn btn-eq-primary btn-sm ms-2"
                      onClick={() => handleAcceptBulkEdit()}
                      {...(isAuthorizedSignatory
                        ? { disabled: false }
                        : { disabled: true })}
                    >
                      <i className="fe fe-check-circle"></i> Accept Changes
                    </button>
                  )}
                  {anyMatchedInvestments && filter === "Accepted" && (
                    <Spinner show={closeBtnLoader}>
                      <button
                        button
                        className="btn btn-eq-primary btn-sm ms-2"
                        onClick={() => {
                          handleCloseAll();
                        }}
                        disabled={!completeStatus}
                      >
                        <i className="fe fe-check-circle"></i>
                        Close All
                      </button>
                    </Spinner>
                  )}
                  {me?.roles?.admin && filter === "Invite Sent" && (
                    <button
                      className="btn btn-eq-primary btn-sm ms-2"
                      onClick={() => deleteAllInvestor()}
                      disabled={selectedItems.length > 0 ? false : true}
                    >
                      <i className="fe fe-trash"></i> Delete All
                    </button>
                  )}
                </div>
              )}
            </div>

            <div className="table-responsive">
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  {matchedInvestments.length ? (
                    <tr>
                      <th></th>
                      <th>Investor</th>
                      <th>Invite Date</th>
                      {!`${offering?.stock_type}`
                        .split(" ")
                        .includes("Safe") && (
                        <th className="text-center">Subscription</th>
                      )}

                      <th className="text-end">Investment Amount</th>
                      {bulkEdit ? <th>Edit Investment Amount</th> : <th></th>}
                      <th className="text-center">Status</th>
                      <th className="text-center">Current Step</th>
                      {isAdmin(me) && <th className="text-center">Action</th>}
                    </tr>
                  ) : (
                    <tr className="noDataExist">
                      <th>No data exist</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {matchedInvestments.map((item, index) => {
                    const telephone = item.user?.phone
                      ? parsePhoneNumber(item.user.phone)
                      : null;

                    return (
                      <tr key={index}>
                        <Box as="td" color="gray.400">
                          <CTooltip
                            label={capitalize(
                              item.user.entity_type.toLowerCase()
                            )}
                          >
                            {item.user.entity_type === "ENTITY" ? (
                              <Icon.Briefcase size="1em" />
                            ) : (
                              <Icon.User size="1em" />
                            )}
                          </CTooltip>
                        </Box>
                        <Box as="td" className="align-baseline">
                          <LinkBox as={Stack} gap={0}>
                            <Text as="span">
                              <LinkOverlay
                                as={Link}
                                to={`/offering/${id}/investment/${item.id}/overview`}
                              >
                                {userFullName(item.user)}
                              </LinkOverlay>
                            </Text>
                            <Flex color="gray.400" align="center" gap={2}>
                              <Icon.Mail size=".85em" />
                              <CLink
                                href={`mailto:${item.user.email}`}
                                rel="external noreferrer noopener"
                                target="_blank"
                                textDecor="underline"
                              >
                                {item.user.email}
                              </CLink>
                            </Flex>
                            <Flex color="gray.400" align="center" gap={2}>
                              <Icon.Phone size=".85em" />
                              <Box
                                as={telephone ? CLink : "span"}
                                href={telephone?.getURI()}
                                textDecor={telephone ? "underline" : null}
                              >
                                {telephone
                                  ? telephone.formatInternational()
                                  : "N/A"}
                              </Box>
                            </Flex>
                          </LinkBox>
                        </Box>
                        <td className="align-baseline">
                          {moment(item.create_date).format("ll")}
                        </td>
                        {!`${offering?.stock_type}`
                          .split(" ")
                          .includes("Safe") && (
                          <td className="text-center align-baseline">
                            {item.approved_quantity &&
                            item?.approved_quantity !== item?.quantity ? (
                              <td>
                                <div>
                                  <NumberFormat
                                    value={item.approved_quantity}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                </div>
                                <del className="small text-muted">
                                  <NumberFormat
                                    value={item.quantity}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                </del>
                              </td>
                            ) : (
                              <NumberFormat
                                value={item.quantity}
                                displayType="text"
                                thousandSeparator={true}
                                decimalScale={0}
                              />
                            )}
                          </td>
                        )}

                        <td className="text-end align-baseline">
                          {item.approved_amount &&
                          item?.amount !== item?.approved_amount ? (
                            <>
                              <Flex justify="end" align="center" gap={2}>
                                {isFunded(item) && (
                                  <CTooltip label="Funded">
                                    <Icon.CheckCircle
                                      aria-hidden="true"
                                      color={green}
                                      size="1em"
                                    />
                                  </CTooltip>
                                )}
                                <NumberFormat
                                  value={item.approved_amount}
                                  displayType="text"
                                  thousandSeparator
                                  decimalScale={0}
                                  prefix="$"
                                />
                              </Flex>
                              <del className="small text-muted">
                                <NumberFormat
                                  value={item.amount}
                                  displayType="text"
                                  thousandSeparator
                                  decimalScale={0}
                                  prefix="$"
                                />
                              </del>
                            </>
                          ) : (
                            <Flex justify="end" align="center" gap={2}>
                              {isFunded(item) && (
                                <CTooltip label="Funded">
                                  <Icon.CheckCircle
                                    aria-hidden="true"
                                    color={green}
                                    size="1em"
                                  />
                                </CTooltip>
                              )}

                              <NumberFormat
                                value={item.amount}
                                displayType="text"
                                thousandSeparator
                                decimalScale={0}
                                prefix="$"
                              />
                            </Flex>
                          )}
                        </td>
                        {bulkEdit && selectedItems.includes(item.id) ? (
                          <td className="align-baseline">
                            <NumberFormat
                              className="form-control"
                              allowNegative={false}
                              thousandSeparator={true}
                              prefix="$"
                              decimalScale={0}
                              value={item.approved_amount}
                              onValueChange={({ value }) =>
                                handleBulkEdit(item.id, value)
                              }
                              isAllowed={({ floatValue }) => {
                                if (!floatValue) {
                                  return true;
                                }
                                return floatValue <= item.amount;
                              }}
                            />
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td className="text-center align-baseline vertical-align-middle">
                          <StatusBadge
                            as={Tag}
                            size="sm"
                            action={item.status}
                          />
                        </td>
                        <td className="text-center align-baseline vertical-align-middle">
                          {item?.event_history?.[0]?.workflow_action &&
                            item?.next && (
                              <EventBadge
                                as={Badge}
                                isPlain
                                size="sm"
                                action={
                                  item?.event_history?.[0]?.workflow_action
                                }
                                event={item?.next}
                              />
                            )}
                        </td>
                        {isAdmin(me) && (
                          <td className="text-center align-baseline">
                            <CTooltip
                              label="Reject Investor"
                              hasArrow
                              placement="auto"
                            >
                              <IconButton
                                size="xs"
                                colorScheme="gray"
                                onClick={() => rejectInvestor(item.id)}
                                aria-label="Reject Investor"
                                icon={<Icon.XCircle color="red" size="1em" />}
                              />
                            </CTooltip>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Spinner>
      </div>
    </>
  );
};

export default Investors;
