/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

import Spinner from "../../../components/spinner";
import Header from "../../../layouts/header";

import TeamInviteModal from "../../../components/users/TeamInviteModal";
import TeammateModal from "../../../components/users/TeammateModal";
import { isAdmin, isIssuerOwner, removeIssuerUser } from "../../../helpers";
import { useIssuer, useIssuerUsers } from "../../../hooks/useIssuers";

const IssuerUsers = () => {
  const { id } = useParams();
  const { data: issuer } = useIssuer(id);
  const {
    data: users,
    isLoading,
    refetch,
  } = useIssuerUsers(id, {
    select: (users) =>
      users
        .filter((user) => !isEmpty(user.roles?.issuer))
        .map((user) => ({
          ...user,
          role: user.roles?.issuer?.[id],
        })),
  });
  const { data: me } = useCurrentUser();

  const [inviteModal, showInviteModal] = useState(false);
  const [userModal, showUserModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const toast = useToast();

  const handleDelete = async (user) => {
    const status = await swal({
      title: `Remove User`,
      text: `Are you sure you want to remove ${[
        user.first_name,
        user.last_name,
      ].join(" ")}?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (!status) return;

    try {
      await removeIssuerUser(user.id, id);
      refetch();
    } catch (e) {
      console.error(e);
      toast({ status: "error", description: e?.response.data });
    }
  };

  return (
    <>
      <Header name="Users" title={issuer?.name}>
        {(isAdmin(me) || isIssuerOwner(me, id)) && (
          <button
            className="btn btn-eq-primary"
            onClick={() => {
              showInviteModal(true);
            }}
          >
            Invite
          </button>
        )}
      </Header>

      <div className="container-fluid">
        <div className="card">
          <Spinner show={!inviteModal && isLoading}>
            <div className="table-responsive">
              <table className="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Title / Company</th>
                    <th>Email</th>
                    <th>Team Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((u, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-baseline">
                          {u.first_name} {u.last_name}
                        </td>
                        <td className="align-baseline">
                          {[u.title, u.company].filter(Boolean).join(", ")}
                        </td>
                        <td className="align-baseline">{u.email}</td>
                        <td className="align-baseline">
                          <span className="badge bg-secondary text-capitalize">
                            {u.roles?.issuer?.[id]}
                          </span>
                        </td>
                        <td className="text-end align-baseline">
                          {(isAdmin(me) || isIssuerOwner(me, id)) && (
                            <>
                              <button
                                className="btn btn-sm btn-light"
                                onClick={() => {
                                  showUserModal(true);
                                  setUserData(u);
                                }}
                              >
                                <i className="fe fe-edit"></i>
                              </button>
                              <button
                                className="btn btn-sm btn-light ms-1"
                                onClick={() => handleDelete(u)}
                              >
                                <i className="fe fe-trash"></i>
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Spinner>
        </div>
      </div>

      <TeamInviteModal
        issuerId={id}
        show={inviteModal}
        onHide={() => showInviteModal(false)}
      />
      <TeammateModal
        show={userModal}
        issuerId={id}
        user={userData}
        onHide={() => {
          showUserModal(false);
          setUserData(null);
          getUsers();
        }}
      />
    </>
  );
};

export default IssuerUsers;
