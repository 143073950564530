import {
  Textarea as CTextarea,
  TextareaProps as CTextareaProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { FC, forwardRef } from "react";

export interface TextareaProps extends CTextareaProps {
  name: string;
  value: any;
  helperText?: string;
  label: string;
  error?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
}

export const Textarea: FC<TextareaProps> = forwardRef(
  (
    {
      name,
      value,
      label,
      helperText,
      isDisabled = false,
      isRequired = false,
      isInvalid = false,
      error,
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl
        isDisabled={isDisabled}
        isRequired={isRequired}
        isInvalid={isInvalid}
        mb="6"
      >
        <FormLabel>{label}</FormLabel>
        <CTextarea value={value ?? undefined} ref={ref} name={name} {...rest} />
        <FormErrorMessage>{error}</FormErrorMessage>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);
