import React from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";

import { userFullName } from "@equidefi/shared";
import { DateTimeService, MomentDateTimeAdapter } from "../../services/DateTimeService";
import { StatusBadge } from "@equidefi/portals/components/investments/StatusBadge";

const dateFormat = new DateTimeService(new MomentDateTimeAdapter());

const TrauncheInvestmentModal = ({ investment = null, onHide = () => {} }) => {
  return (
    <Modal show={!!investment} size="xl" centered onHide={onHide}>
      <Modal.Header closeButton>
        <h3 className="fs-1 mb-0">{userFullName(investment?.user)}</h3>
      </Modal.Header>
      <Modal.Body>
        <Tabs>
          <Tab eventKey="statusHistory" title="Status History">
            <div
              className="table-responsive mb-4"
              style={{ overflowY: "auto", maxHeight: "30em" }}
            >
              <table className="table fs-5">
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Action</th>
                    <th>User/Actor</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {investment?.event_history?.map((event) => (
                    <tr key={event.id}>
                      <td className="align-baseline">{event.workflow_event}</td>
                      <td className="align-baseline">
                        <StatusBadge action={event.workflow_action} />
                      </td>
                      <td className="align-baseline">
                        {event.service ??
                          `${event?.user?.first_name} ${event?.user?.last_name}`.trim()}
                      </td>
                      <td className="align-baseline">
                        {dateFormat.formatMonthDayYear(event.create_date)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default TrauncheInvestmentModal;
