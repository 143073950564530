import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Select as ReactSelect } from "chakra-react-select";
import { FC, forwardRef } from "react";
import { Options } from "react-select";

export interface SelectProps {
  name: string;
  label: string;
  error?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
  options?: Options<{
    label: string;
    value: unknown;
  }>;

  [key: string]: unknown;
}

export const Select: FC<SelectProps> = forwardRef(
  (
    {
      name,
      label,
      isRequired = false,
      isInvalid = false,
      error,
      options = [],
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl isRequired={isRequired} isInvalid={isInvalid} mb="6">
        <FormLabel>{label}</FormLabel>
        <ReactSelect
          name={name}
          isInvalid={isInvalid}
          options={options}
          {...rest}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  }
);
