import { Alert, Box, Flex, Spacer } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import React from "react";

import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import CopyableText from "../CopyableText";
import SendReminderButton from "./SendReminderButton";

const SignatureIcon = ({ isChecked = false, ...rest }) => {
  if (isChecked) {
    return <Icon.CheckCircle {...rest} />;
  }

  return <Icon.Circle {...rest} />;
};

export const InvestmentSignatureBox = ({
  investmentId,
  signature,
  onRemind = () => {},
}) => {
  return (
    <Alert
      as="li"
      status={signature.status === "signed" ? "success" : "warning"}
      borderWidth="1px"
      borderRadius="lg"
      px="3"
      py=".5"
      backgroundColor={
        signature.status === "signed"
          ? transparentize("equidefi.green", 0.2)
          : transparentize("equidefi.yellow", 0.2)
      }
      borderColor={
        signature.status === "signed" ? "equidefi.green" : "equidefi.yellow"
      }
      w="full"
      shadow="sm"
    >
      <Flex gap="2" align="center" flexFlow="row nowrap" w="full">
        <Box flexShrink={0}>
          <SignatureIcon
            isChecked={signature.status === "signed"}
            size="21px"
          />
        </Box>

        <Text
          isTruncated
          w="30%"
          flexShrink="0"
          flexBasis="10em"
          mb="0"
          as="span"
          textStyle="h3"
          fontWeight="600"
        >
          {signature.name}
        </Text>

        <CopyableText text={signature.email} />
        <CopyableText text={signature.phone} />

        <Spacer />

        <SendReminderButton
          investmentId={investmentId}
          isVisible={signature.remindable}
          signature={signature}
        />
      </Flex>
    </Alert>
  );
};
