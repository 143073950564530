import
  {
    Button,
    ButtonGroup,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
  } from "@chakra-ui/react";
import RadioGroup from "@equidefi/ui/forms/RadioGroup";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import ReactSelect from "react-select";
import * as Yup from "yup";

import { useAgreementTemplates } from "@equidefi/portals/hooks/useSignatures";
import { Input } from "@equidefi/ui";

import { MESSAGE } from "../../constants/forms";
import useDebounce from "../../hooks/useDebounce";

const offeringAgreementSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE.REQUIRED("name")),
  template_id: Yup.string().required(MESSAGE.REQUIRED("template ID")),
  entity_type: Yup.string().required(MESSAGE.REQUIRED("entity type")),
});

const offeringAgreementUpdateSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE.REQUIRED("name")),
});

const entityTypeOptions = [
  { label: "Individual (or Joint)", value: "INDIVIDUAL" },
  { label: "Entity", value: "ENTITY" },
];

export const OfferingAgreementModal = ({
  agreement,
  isOpen,
  isSubmitting = false,
  onClose,
  onComplete = () => {},
}) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);

  const handleOnSubmit = (data) => {
    onComplete(data);
  };

  const {
    data: results,
    status,
    fetchStatus,
  } = useAgreementTemplates(searchVal);

  const isUpdate = !!agreement?.id;
  const isLoading = useMemo(
    () =>
      (status === "loading" || fetchStatus === "fetching") &&
      inputSearchValue.length > 0,
    [inputSearchValue, status, fetchStatus]
  );

  const options = useMemo(() => {
    return (
      results?.templates?.map((option) => ({
        ...option,
        label: option.title,
        value: option.templateId,
      })) ?? []
    );
  }, [results]);

  const initialValues = isUpdate
    ? {
        name: agreement?.name,
      }
    : {
        name: agreement?.name,
        template_id: agreement?.template_id,
        entity_type: agreement?.entity_type,
      };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading mb="0" size="md">
            {isUpdate ? "Edit" : "Add"} Agreement
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            isUpdate ? offeringAgreementUpdateSchema : offeringAgreementSchema
          }
          onSubmit={handleOnSubmit}
        >
          {({
            errors,
            touched,
            getFieldProps,
            setFieldValue,
            isValid,
            values,
          }) => (
            <Form noValidate>
              <ModalBody>
                <Input
                  label="Name"
                  isRequired
                  isInvalid={touched.name && errors.name}
                  error={errors.name}
                  {...getFieldProps("name")}
                  placeholder="eg: Subscription Booklet"
                />

                {!isUpdate && (
                  <FormControl mb="2">
                    <FormLabel>Template</FormLabel>
                    <ReactSelect
                      autoFocus
                      placeholder="Select a Template"
                      noOptionsMessage={() => "No templates found"}
                      filterOption={null}
                      value={inputSearchValue}
                      onInputChange={(value) => {
                        setInputSearchValue(value);
                      }}
                      onChange={(value) => {
                        setFieldValue("name", value.label);
                        setFieldValue("template_id", value.value);
                      }}
                      isDisabled={isUpdate}
                      isLoading={isLoading}
                      options={options}
                      components={{
                        IndicatorSeparator: null,
                      }}
                    />
                  </FormControl>
                )}

                {!isUpdate && (
                  <Input
                    label="Template ID"
                    isReadOnly
                    isRequired
                    isInvalid={touched.template_id && errors.template_id}
                    error={errors.template_id}
                    cursor="not-allowed"
                    {...getFieldProps("template_id")}
                  />
                )}

                {!isUpdate && (
                  <FormControl
                    isRequired
                    isDisabled={isUpdate}
                    isInvalid={errors.entity_type}
                  >
                    <FormLabel>Entity Type</FormLabel>
                    <RadioGroup
                      fieldName="entity_type"
                      defaultValue={values.entity_type}
                      options={entityTypeOptions}
                      errors={touched.entity_type && errors.entity_type}
                      setFieldValue={setFieldValue}
                    />
                    <FormErrorMessage>{errors.entity_type}</FormErrorMessage>
                  </FormControl>
                )}
              </ModalBody>

              <ModalFooter justifyContent="start">
                <ButtonGroup isLoading={isSubmitting} size="md">
                  <Button type="submit">{isUpdate ? "Save" : "Add"}</Button>
                  <Button colorScheme="gray" variant="ghost" onClick={onClose}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
