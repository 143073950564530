import { Button } from "@chakra-ui/react";
import axios from "axios";
import { Formik } from "formik";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

import Header from "../../layouts/header";

const MyProfile = (props) => {
  const dispatch = useDispatch();

  const { data: user } = useCurrentUser();
  const [spinner, setSpinner] = useState(false);

  const profileSchema = Yup.object().shape({
    first_name: Yup.string().required("Please enter your first name"),
    middle_name: Yup.string().nullable(),
    last_name: Yup.string().required("Please enter your last name"),
    company: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    phone: Yup.string()
      .matches(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Please provide a valid phone number"
      )
      .nullable(),
  });

  const handleSubmit = async (form) => {
    setSpinner(true);

    const request = {
      first_name: form.first_name,
      middle_name: form.middle_name,
      last_name: form.last_name,
      phone: form.phone,
      company: form.company,
      title: form.title,
      active: true,
    };

    axios.post(`user/${user?.id}`, request).then((response) => {
      axios.get(`user/${user?.id}`).then((response) => {
        toast.success(`Your profile has been updated successfully`);
        let data = {
          ...user,
          first_name: response.data.first_name,
          middle_name: response.data.middle_name,
          last_name: response.data.last_name,
          phone: response.data.phone,
          company: response.data.company,
          title: response.data.title,
          active: true,
        };
        dispatch({ type: "user", payload: data });
        setSpinner(false);
      });
    });
  };

  return (
    <>
      <Header name="My Profile"></Header>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-6">
            <div className="card">
              <div className="card-body">
                <Formik
                  initialValues={user}
                  validationSchema={profileSchema}
                  enableReinitialize
                  onSubmit={handleSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    handleBlur,
                    setFieldValue,
                    getFieldProps,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-group col">
                          <label className="form-label">
                            First Name<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            {...getFieldProps("first_name")}
                            type="text"
                            placeholder="e.g. John"
                          />
                          {errors.first_name && touched.first_name && (
                            <div className="text-danger mt-2 ms-1 h5">
                              {errors.first_name}
                            </div>
                          )}
                        </div>
                        <div className="form-group col">
                          <label className="form-label">Middle Name</label>
                          <input
                            className="form-control"
                            {...getFieldProps("middle_name")}
                            type="text"
                            placeholder="e.g. John"
                          />
                          {errors.middle_name && touched.middle_name && (
                            <div className="text-danger mt-2 ms-1 h5">
                              {errors.middle_name}
                            </div>
                          )}
                        </div>
                        <div className="form-group col">
                          <label className="form-label">
                            Last Name<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            {...getFieldProps("last_name")}
                            type="text"
                            placeholder="e.g. John"
                          />
                          {errors.last_name && touched.last_name && (
                            <div className="text-danger mt-2 ms-1 h5">
                              {errors.last_name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Title<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="title"
                          placeholder="e.g. Investor"
                          value={values.title}
                          onChange={handleChange}
                        />
                        {errors.title && touched.title && (
                          <div className="text-danger mt-2 ms-1 h5">
                            {errors.title}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Company<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="company"
                          placeholder="e.g. Equidefi"
                          value={values.company}
                          onChange={handleChange}
                        />
                        {errors.company && touched.company && (
                          <div className="text-danger mt-2 ms-1 h5">
                            {errors.company}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Email Address<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control bg-light"
                          type="text"
                          name="email"
                          placeholder="e.g. address@example.com"
                          value={values.email}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger mt-2 ms-1 h5">
                            {errors.email}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Phone</label>
                        <NumberFormat
                          placeholder="eg: +1 (444) 444-4444"
                          className="form-control"
                          format="+1 (###) ###-####"
                          mask="_"
                          value={values.phone}
                          onValueChange={({ value }) => {
                            setFieldValue("phone", value);
                          }}
                        />
                        {errors.phone && touched.phone && (
                          <div className="text-danger mt-2 ms-1 h5">
                            {errors.phone}
                          </div>
                        )}
                      </div>

                      <Button
                        isLoading={spinner}
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Update Profile
                      </Button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6 d-none d-lg-flex">
            <img
              src="/img/profile.svg"
              className="img-fluid mb-6 mb-md-0 aos-init aos-animate"
              alt="..."
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
