import { ApiInstance } from "./api";

export default class StripeClient {
  static async createSetupIntent(issuerId) {
    const response = await ApiInstance.post("/v2/stripe/setup-intent/", {
      issuer_id: issuerId,
    });
    return response.data;
  }

  static async getSetupIntent(issuerId) {
    const response = await ApiInstance.get(
      `/v2/stripe/setup-intent/${issuerId}`
    );
    return response;
  }

  static async savePaymentMethod(issuerId, setupIntentId) {
    const response = await ApiInstance.post("/v2/stripe/payment-method/", {
      issuer_id: issuerId,
      setup_intent_id: setupIntentId,
    });
    return response.data;
  }

  static async getPaymentMethod(issuerId) {
    const response = await ApiInstance.get(
      `/v2/stripe/payment-method/${issuerId}`
    );
    return response.data;
  }
}
