import
  {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
  } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import Select from "react-select";
import * as Yup from "yup";

import
  {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
  } from "@chakra-ui/react";

import { useInvestmentUpdate } from "../../../hooks/investments";


// TODO: Reference values from Prisma types once DB package is extracted
const WorkflowEvent = [
  "REGISTRATION",
  "SUBSCRIPTION",
  "PAYMENT",
  "PROFILE",
  "ADDRESS",
  "SIGNERS",
  "KYC",
  "AGREEMENT",
  "ACCREDITATION",
  "DILIGENCE",
  "COUNTERSIGN",
  "REFUND",
  "CLOSING",
];

const statusOptions = WorkflowEvent.map((event) => ({
  label: event,
  value: event,
}));

const ModalUpdateWorkflowStep = ({
  investmentId,
  show = false,
  onSubmit = () => {},
  onHide = () => {},
}) => {
  const toast = useToast();
  const updateStatus = useInvestmentUpdate(investmentId);

  const handleSubmit = async (data) => {
    try {
      await updateStatus.mutateAsync({
        next: data.next?.value ?? null,
      });

      toast({
        status: "success",
        description: "Workflow step successfully updated",
      });
      onSubmit(data);
    } catch (e) {
      toast({
        status: "error",
        description: "Sorry, the workflow step could not be updated",
      });
    }
  };

  return (
    <Modal isOpen={show} onClose={onHide}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader closeButton onHide={onHide}>
          <span className="fs-2">Investment Current Step</span>
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          enableReinitialize
          initialValues={{
            next: null,
          }}
          validationSchema={Yup.object().shape({
            next: Yup.object().nullable(),
          })}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ touched, errors, setFieldValue }) => (
            <Form>
              <ModalBody>
                <FormControl isInvalid={errors.next && touched.next}>
                  <FormLabel>Workflow Step</FormLabel>

                  <Select
                    autoFocus={false}
                    placeholder={"Select a Step"}
                    onChange={(value) => {
                      setFieldValue("next", value);
                    }}
                    options={statusOptions}
                    components={{
                      IndicatorSeparator: null,
                    }}
                  />
                  <FormErrorMessage>{errors.next}</FormErrorMessage>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button size="sm" type="submit">
                  Update
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ModalUpdateWorkflowStep;
