import
  {
    Alert,
    AlertDescription,
    AlertIcon,
    ButtonGroup,
    Stack,
    useToast,
  } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import DownloadButton from "@equidefi/portals/components/investments/DownloadButton";
import { useEmbeddedSignature } from "@equidefi/portals/hooks/useSignatures";
import { Heading, Paper } from "@equidefi/ui";
import CountersignButton from "./CountersignButton";

import InvestmentSignatureList from "@equidefi/portals/components/investments/InvestmentSigantureList";
import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import { CONFIG } from "../../../constants/config";
import VoidAgreementButton from "./VoidAgreementButton";

const InvestentAgreementCard = ({
  agreement,
  signatures = [],
  isCountersignable = false, // investment-level countersigning status
  onCountersign = async () => {},
}) => {
  const [closed, setClosed] = useState(false);
  const toast = useToast();

  const investors = useMemo(
    () =>
      signatures?.filter((s) =>
        s.role ? s.role !== "countersigner" : s.order !== null
      ) ?? [],
    [signatures]
  );

  const countersigner = useMemo(
    () =>
      signatures?.find((s) =>
        s.role ? s.role === "countersigner" : s.order === null
      ),
    [signatures]
  );

  const { data, refetch: refetchSignatures } = useInvestmentSignatures(
    agreement?.investment_id,
    {
      refetchInterval: closed ? 2_500 : false,
    }
  );

  const summary = data?.summary ?? {};
  const isCountersigned = summary?.isCountersigned || false;

  const counterSignature = useEmbeddedSignature({
    testMode: CONFIG.ENV !== "production",
    skipDomainVerification: CONFIG.ENV !== "production",
    onError: () => {
      toast({ status: "success", description: "Countersigning failed" });
    },
    onCancel: () => {
      toast({ status: "info", description: "Countersigning incomplete" });
    },
    onClose: async () => {
      setClosed(true);
      toast({ status: "success", description: "Countersigning complete!" });
      onCountersign();
    },
  });

  useEffect(() => {
    if (isCountersigned) setClosed(false);
  }, [closed, isCountersigned]);

  const onCountersignClick = useCallback(async () => {
    try {
      await counterSignature.start(agreement?.id, countersigner?.signature_id);
    } catch (e) {
      toast({
        status: "error",
        description: "Countersigning could not be started.",
      });
    }
  }, [agreement?.id, counterSignature, countersigner?.signature_id, toast]);

  return (
    <Paper mb={8} boxShadow="none" p="5">
      <Stack direction="row" justify="space-between" w="full">
        <Heading textStyle="h2">Subscription Agreement</Heading>
        <ButtonGroup size="sm" gap="2">
          <VoidAgreementButton
            agreement={agreement}
            variant="outline"
            onVoided={() => refetchSignatures()}
          />
          <DownloadButton
            investmentId={agreement?.investment_id}
            variant="outline"
          >
            Download
          </DownloadButton>
          <CountersignButton
            investmentId={agreement?.investment_id}
            onClick={onCountersignClick}
            isCountersignable={isCountersignable}
          >
            Countersign
          </CountersignButton>
        </ButtonGroup>
      </Stack>

      {investors.length ? (
        <>
          <Heading textStyle="h3" fontSize="xs" textTransform="uppercase">
            Investors
          </Heading>
          <InvestmentSignatureList
            investmentId={agreement?.investment_id}
            investors={investors}
          />
          <Heading textStyle="h3" fontSize="xs" textTransform="uppercase">
            Countersigner
          </Heading>
          {countersigner && (
            <InvestmentSignatureList investors={[countersigner]} />
          )}
        </>
      ) : (
        <Alert status="info" variant="top-accent">
          <AlertIcon />
          <AlertDescription>
            No signers have been added to this investment yet.
          </AlertDescription>
        </Alert>
      )}
    </Paper>
  );
};

export default InvestentAgreementCard;
