import {
    Badge,
    Tag as CTag,
    TagLabel,
    TagLeftIcon,
    Tooltip,
} from "@chakra-ui/react";
import React from "react";

import { Icon } from "@equidefi/ui/icon";

const BadgeTypeMap = {
    PENDING: "gray",
    SUBMITTED: "blue",
    APPROVED: "green",
    REJECTED: "red",
    COMPLETED: "green",
    REVIEW: "blue",
};

const BadgeIconMap = {
    PENDING: Icon.Clock,
    SUBMITTED: Icon.FileText,
    APPROVED: Icon.ThumbsUp,
    REJECTED: Icon.X,
    COMPLETED: Icon.Check,
    REVIEW: Icon.Search,
};

export const StatusBadge = ({ as: Tag = Badge, action, ...rest }) => {
    return (
        <Tag colorScheme={BadgeTypeMap[action] || "gray"} {...rest}>
            {action}
        </Tag>
    );
};

export const EventBadge = ({ event, action, isPlain = false, ...rest }) => {
    const BadgeIcon = BadgeIconMap[action];
    const colorScheme = isPlain ? "gray" : BadgeTypeMap[action] ?? "gray";

    return (
      <Tooltip placement="left" hasArrow label={`${event} ${action}`}>
        <CTag colorScheme={colorScheme} {...rest}>
          {!isPlain && (
            <TagLeftIcon>
              <BadgeIcon />
            </TagLeftIcon>
          )}
          <TagLabel>{event}</TagLabel>
        </CTag>
      </Tooltip>
    );
  };
