export const PersonaDashboardBase = new URL("https://app.withpersona.com");

export const INQUIRY_STATUS_ALERT_TYPE = {
  completed: "info",
  approved: "green",
  needs_review: "teal",
  pending: "gray",
  created: "gray",
  declined: "red",
  expired: "orange",
};

export const INQUIRY_STATUS_TEXT = {
  completed: "Completed",
  approved: "Approved",
  declined: "Declined",
  needs_review: "Needs Review",
  pending: "Pending",
  created: "Created",
  expired: "Expired",
};
