import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  Center,
  Container,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import UserToken from "@equidefi/portals/clients/UserToken";
import { useMFACode } from "@equidefi/portals/hooks/useAuthentication";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

const MFA = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const email = useSelector((state) => state.email);
  const userData = useCurrentUser();
  const confirmMFA = useMFACode();
  const [mfaCode, setMfaCode] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await confirmMFA.mutateAsync({
        email: email,
        mfa_code: mfaCode,
      });
      UserToken.setToken(data.token);
      userData.refetch();
      dispatch({
        type: "user",
        payload: {
          ...data.user,
          token: data.token,
        },
      });
      history.push("/offering");
    } catch (error) {
      console.log(error);
      toast.error("Incorrect code, please try again");
    }
  };

  return (
    <section className="vh-100 d-flex align-items-center bg-auth border-top border-top-2 border-primary">
      <Container maxW="lg">
        <form onSubmit={handleLogin}>
          <Stack justify="center" align="center">
            <Stack spacing="3">
              <Center>
                <Image
                  src="/img/logo.svg"
                  w="200px"
                  mb="4"
                  alt="Equidefi Logo"
                  display="bock"
                />
              </Center>

              <Heading size="lg" as="h1" textAlign="center" mb="0">
                Two Factor Authentication
              </Heading>

              <Text textAlign="center" mb="4">
                Your account is protected with two-factor authentication. We've
                sent you an email. Please enter the code below.
              </Text>
            </Stack>

            <NumberFormat
              name="mfa_code"
              className="form-control mfa-code"
              format="######"
              mask="_"
              placeholder="0 0 0 0 0 0"
              value={mfaCode}
              onValueChange={({ value }) => setMfaCode(value)}
            />

            <Button
              size="lg"
              isLoading={confirmMFA.isLoading}
              isDisabled={mfaCode.length !== 6}
              type="submit"
              mt="4"
            >
              Continue
            </Button>
          </Stack>
        </form>
      </Container>
    </section>
  );
};

export default MFA;
