import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Header } from "@equidefi/ui";

import Spinner from "../../../components/spinner";
import TeamInviteModal from "../../../components/users/TeamInviteModal";
import { ISSUER } from "../../../constants/issuer";
import { isAdmin, isIssuerOwner } from "../../../helpers";
import
  {
    useDeleteInvite,
    useInvitationList,
  } from "../../../hooks/useInvitations";
import { useIssuer } from "../../../hooks/useIssuers";
import styles from "./styles.module.css";

const Invitations = () => {
  const { issuerId } = useParams();
  const { data: issuer } = useIssuer(issuerId);
  const toast = useToast()
  const { data: me } = useCurrentUser();
  const {
    data: invitations,
    isLoading,
    refetch,
  } = useInvitationList(ISSUER, issuer?.id);
  const deleteInvite = useDeleteInvite();
  const [modal, showModal] = useState(false);

  const handleDelete = async (invitationId) => {
    try {
      await deleteInvite.mutateAsync({ invitationId });
      toast({ status: "success", description: "Invitation removed"} );
      refetch();
    } catch (error) {
      console.log(error?.response);
      toast({ status: "error", description: "Error deleting invitation" });
    }
  };

  return (
    <div className={styles.root}>
      <Header
        subtitle={issuer?.name}
        styles={styles}
        buttonLabel={
          isAdmin(me) || isIssuerOwner(me, issuer?.id) ? "Invite" : null
        }
        onClick={() => showModal(true)}
      >
        Issuer Invitations
      </Header>
      <div className="container-fluid mt-5">
        <div className="card">
          <Spinner show={isLoading}>
            <div className="table-responsive">
              <table className="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Role</th>
                    <th>Message</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {invitations?.map((invite, index) => {
                    return (
                      <tr key={index}>
                        <td>{invite.email}</td>
                        <td>{invite.status}</td>
                        <td>{invite.role.name}</td>
                        <td>{invite.email_log.status}</td>
                        <td>
                          {(isAdmin(me) || isIssuerOwner(me, issuer?.id)) && (
                            <button
                              className="btn btn-sm btn-light ms-1"
                              onClick={() => handleDelete(invite.id)}
                            >
                              <i className="fe fe-trash"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TeamInviteModal
              issuerId={issuerId}
              show={modal}
              onHide={() => {
                showModal(false);
                refetch();
              }}
            />
          </Spinner>
        </div>
      </div>
    </div>
  );
};

export default Invitations;
