import { ApiClient } from "./ApiClient";

class OfferingClient extends ApiClient {
  async search(search?: string) {
    const response = await this.get("/offering", { params: { search } });
    return response.data;
  }

  async investments(id: string, search: string) {
    const response = await this.get(`/v2/offerings/${id}/investments`, {
      params: search ? { search } : {},
    });
    return response.data;
  }

  async updateSignatory(id: string, sigantoryId: string) {
    const response = await this.patch(`/v2/offerings/${id}/signatory`, {
      signatory_id: sigantoryId,
    });
    return response.data;
  }

  async legacyCreate(data: unknown) {
    const response = await this.post(`/offering`, data);
    return response.data;
  }

  async legacyGetSettings(id: string) {
    const response = await this.get(`/offering/${id}/setting`);
    return response.data;
  }

  async legacyFind(id: string) {
    const response = await this.get(`/offering/${id}`);
    return response.data;
  }

  async getFullById(id: string) {
    const response = await this.get(`/v2/offerings/${id}/full`);
    return response.data ?? {};
  }

  async getById(id: string) {
    const response = await this.get(`/v2/offerings/${id}`);
    return response.data?.data;
  }

  async update(id: string, data: unknown) {
    const response = await this.patch(`/v2/offerings/${id}`, data);
    return response.data;
  }

  async remove(id: string) {
    const response = await this.delete(`/offering/${id}`);
    return response.data;
  }

  async settings(id: string) {
    const response = await this.get(`/offering/${id}/setting`);
    return response.data;
  }

  async brokers(id: string) {
    const response = await this.get(`/v2/offerings/${id}/brokers`);
    return response.data;
  }

  async getTraunches(offeringId: string) {
    const response = await this.get(`/v2/offerings/${offeringId}/traunches`);
    return response.data;
  }

  async getAvailableInvestments(offeringId: string) {
    const response = await this.get(
      `/v2/offerings/${offeringId}/traunches/investments`
    );
    return response.data;
  }

  async getTranche(id: string, offeringId: string) {
    const response = await this.get(
      `/v2/offerings/${offeringId}/traunches/${id}`
    );
    return response.data;
  }

  async createTruanche(offeringId: string) {
    const response = await this.post(`/v2/offerings/${offeringId}/traunches`);
    return response.data;
  }

  async getTrauncheDocument(id: string, offeringId: string, document: string) {
    const response = await this.get(
      `/v2/offerings/${offeringId}/traunches/${id}/document/${document}`
    );

    return response.data;
  }

  async authenticate(email: string, offeringId: string) {
    const response = await this.post(`/v2/offerings/${offeringId}/auth`, {
      email,
    });
    return response.data;
  }

  async register(data: Record<string, any>, offeringId) {
    const response = await this.post(
      `/v2/offerings/${offeringId}/register`,
      data
    );
    return response.data;
  }

  async issuerOfferings(issuerId: string) {
    const response = await this.get(`/v2/offerings/issuer/${issuerId}`);
    return response.data;
  }

  async userOfferings() {
    const response = await this.get('/v2/offerings/user');
    return response.data;
  }
}

export default OfferingClient;
