import { useMutation, useQuery } from "@tanstack/react-query";

import BrokerClient from "@equidefi/portals/clients/BrokerClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { ApiInstance } from "../clients/api";

/**
 * @param {import("@tanstack/react-query").UseQueryOptions} options
 */
export const useBrokerList = (options = {}) => {
  const brokerApi = useApi(BrokerClient);

  return useQuery(["brokers"], async () => brokerApi.all(), options);
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseQueryOptions} options
 */
export const useBroker = (id, options = {}) => {
  const brokerApi = useApi(BrokerClient);

  return useQuery(["brokers", id], async () => brokerApi.find(id), {
    enabled: !!id,
    ...options,
  });
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useBrokerCreate = (options = {}) => {
  const brokerApi = useApi(BrokerClient);

  return useMutation(({ data }) => brokerApi.create(data), options);
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useBrokerUpdate = (id, options = {}) => {
  const brokerApi = useApi(BrokerClient);

  return useMutation(({ data }) => brokerApi.update(id, data), options);
};

/**
 * TODO: Split API request into BrokerClient and separate react-select data structure
 */
export const searchBroker = async (q) => {
  const response = await ApiInstance.get(`/v2/brokers/search`, {
    params: { q },
  });

  return response.data.map((value) => ({
    label: `${value.first_name} ${value.last_name} (${value.crd_number})`,
    value: value,
  }));
};
