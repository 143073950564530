import { Box, IconButton } from "@chakra-ui/react";
import { Paper } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import React from "react";

const Card = ({ title, children, editable, onClick }) => {
  return (
    <Box mb={6} className="col">
      <Paper py={5} px={6}>

          <div className="row align-items-center">
            <div className="col">
              <div className="d-flex justify-content-between">
                <h6 className="text-uppercase text-muted">{title}</h6>
                {editable && (
                  <IconButton
                    size="xs"
                    colorScheme="gray"
                    icon={<Icon.Edit size="1em" />}
                    aria-label="Edit Status"
                    onClick={onClick}
                  />
                )}
              </div>
              <h2 className="mb-0">{children}</h2>
            </div>
          </div>

      </Paper>
    </Box>
  );
};

export default Card;
