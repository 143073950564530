import { ApiInstance } from "./api";

class OfferingClient {
  static async legacyCreate(data) {
    const response = await ApiInstance.post(`/offering`, data);
    return response.data;
  }

  static async update(id, data) {
    const response = await ApiInstance.patch(`/v2/offerings/${id}`, data);
    return response.data;
  }

  static async settings(id) {
    const response = await ApiInstance.get(`/offering/${id}/setting`);
    return response.data;
  }
  static async getTraunches(offeringId) {
    const response = await ApiInstance.get(
      `/v2/offerings/${offeringId}/traunches`
    );
    return response.data;
  }

  static async getAvailableInvestments(offeringId) {
    const response = await ApiInstance.get(
      `/v2/offerings/${offeringId}/traunches/investments`
    );
    return response.data;
  }

  static async getTraunche(id, offeringId) {
    const response = await ApiInstance.get(
      `/v2/offerings/${offeringId}/traunches/${id}`
    );
    return response.data;
  }

  static async createTruanche(offeringId) {
    const response = await ApiInstance.post(
      `/v2/offerings/${offeringId}/traunches`
    );
    return response.data;
  }

  static async getTrauncheDocument(id, offeringId, document) {
    const response = await ApiInstance.get(
      `/v2/offerings/${offeringId}/traunches/${id}/document/${document}`
    );

    return response.data;
  }

  static async issuerOfferings(issuerId) {
    const response = await ApiInstance.get(`/v2/offerings/issuer/${issuerId}`);
    return response.data;
  }
}

export default OfferingClient;
