import { Formik } from "formik";
import React from "react";

import { Box, Button } from "@chakra-ui/react";
import { PhoneField } from "@equidefi/portals/components/Auth";
import { Input, Password } from "@equidefi/ui";
import { issuerUserSchema } from "../../constants/forms";

const IssuerUserForm = ({ invitation, isLoading, onSubmit }) => {
  return (
    <Box maxW="400px">
      <Formik
        enableReinitialize
        initialValues={{
          first_name: "",
          middle_name: "",
          last_name: "",
          title: "",
          company: "",
          email: invitation?.email ?? "",
          phone: "",
          password: "",
          confirm_password: "",
        }}
        validationSchema={issuerUserSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleSubmit,
          setFieldValue,
          getFieldProps,
          values,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Input
                label="First Name"
                error={errors.first_name}
                isInvalid={errors.first_name && touched.first_name}
                isRequired
                {...getFieldProps("first_name")}
              />
              <Input
                label="Middle Name"
                error={errors.middle_name}
                isInvalid={errors.middle_name && touched.middle_name}
                {...getFieldProps("middle_name")}
              />
              <Input
                label="Last Name"
                error={errors.last_name}
                isInvalid={errors.last_name && touched.last_name}
                isRequired
                {...getFieldProps("last_name")}
              />
              <Input
                disabled
                label="Email"
                error={errors.email}
                isInvalid={errors.email && touched.email}
                isRequired
                {...getFieldProps("email")}
              />
              <PhoneField
                error={touched?.phone && errors?.phone}
                setFieldValue={setFieldValue}
                {...getFieldProps("phone")}
              />
              <Input
                label="Title"
                error={errors.title}
                isInvalid={errors.title && touched.title}
                {...getFieldProps("title")}
              />
              <Input
                label="Company"
                error={errors.company}
                isInvalid={errors.company && touched.company}
                {...getFieldProps("company")}
              />
              <Password
                label="Password"
                error={errors.password}
                isInvalid={errors.password && touched.password}
                isRequired
                {...getFieldProps("password")}
              />
              <Password
                label="Confirm Password"
                error={errors.confirm_password}
                isInvalid={errors.confirm_password && touched.confirm_password}
                isRequired
                {...getFieldProps("confirm_password")}
              />

              <Button isLoading={isLoading} type="submit" w="full" mb="5">
                Submit
              </Button>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default IssuerUserForm;
