/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import NumberFormat from "react-number-format";
import { Link, useParams } from "react-router-dom";

import moment from "moment";
import swal from "sweetalert";

import
  {
    Button,
    Flex,
    HStack,
    Spacer,
    Tag,
    useClipboard,
  } from "@chakra-ui/react";
import { ReadMore } from "@equidefi/portals/components/ReadMore";
import { useApiInstance } from "@equidefi/portals/hooks/useApi";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { userFullName } from "@equidefi/shared";
import { Icon } from "@equidefi/ui/icon";
import OfferingStatusBadge from "../../components/OfferingStatusBadge";
import Card from "../../components/card";
import OfferingSignatoryButton from "../../components/offerings/OfferingSignatoryButton";
import Spinner from "../../components/spinner";
import { CONFIG } from "../../constants/config";
import { CurrencyFormatter } from "../../constants/format";
import
  {
    IconControl,
    isAdmin,
    isOfferingEditor,
    isOfferingOwner,
    stockTypeName,
    stockTypeOfferingPage,
  } from "../../helpers";
import { useOfferingFull } from "../../hooks/useOfferings";
import Header from "../../layouts/header";
import OfferingStatusMenu from "./components/OfferingStatusMenu";

const fromCurrencyNumber = (v) => Number(v?.replace(/[^0-9.-]+/g, ""));

const Overview = () => {
  const { id } = useParams();
  const apiInstance = useApiInstance();

  const { data, isFetching, refetch: refetchOffering } = useOfferingFull(id);
  const { offering, summary } = data ?? {};

  const { data: me }= useCurrentUser();
  const isSpinning = useMemo(
    () => isFetching || !offering,
    [isFetching, offering]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateStatus = async (status, value) => {
    const result = await swal({
      title: `Update Status`,
      text: `Are you sure you want to update ${offering.name} to "${status}"?`,
      buttons: ["Cancel", "Yes"],
      icon: "warning",
    });

    if (result) {
      await apiInstance.post(`offering/${id}/status/${value}`);
      refetchOffering();
    }
  };

  const hasPermissionToEdit = useMemo(
    () =>
      isOfferingEditor(me, offering?.id) ||
      isOfferingOwner(me, offering?.id) ||
      isAdmin(me),
    [me, offering]
  );

  const canChangeOfferingStatus = useMemo(
    () => isOfferingOwner(me, offering?.id) || isAdmin(me),
    [me, offering]
  );

  const canEditOffering = useMemo(
    () => hasPermissionToEdit && ["Draft"].includes(offering?.status),
    [hasPermissionToEdit, offering]
  );

  const url = new URL(
    `/offerings/${offering?.slug}/registration`,
    CONFIG.EQ_APP_URL
  );

  const shareUrl = useClipboard(url.toString());

  const hasExercisePrice2 =
    offering?.additional_info?.unit_exercise_price_2 ||
    offering?.additional_info?.term_2;

  if (isSpinning) {
    return <Spinner />;
  }

  return (
    <>
      <Header id={id} name="Overview" title={offering?.name}>
        <Spinner show={isSpinning}>
          <Flex gap="2" align="center">
            <Button
              colorScheme="gray"
              color="gray.500"
              variant="ghost"
              size="sm"
              leftIcon={<Icon.Clipboard size="1em" />}
              onClick={shareUrl.onCopy}
            >
              {shareUrl.hasCopied ? "Copied!" : "Share URL"}
            </Button>

            {!canChangeOfferingStatus && (
              <OfferingStatusBadge
                as={Tag}
                status={offering?.status}
                size="lg"
              />
            )}

            {canChangeOfferingStatus && (
              <OfferingStatusMenu
                user={me}
                currentStatus={offering?.status}
                onSelect={(status, key) => updateStatus(status, key)}
              />
            )}

            {canEditOffering && (
              <Button
                as={Link}
                leftIcon={<Icon.Edit2 size="1em" />}
                size="sm"
                to={`/offering/${id}/wizard/account`}
              >
                Edit Offering
              </Button>
            )}
          </Flex>
        </Spinner>
      </Header>

      <div className="container-fluid">
        <div className="row">
          <Card title="Launch Date" icon={"/img/dashboard/Shipments.svg"}>
            {offering?.launch_date
              ? moment(offering?.launch_date).format("LL")
              : "---"}
          </Card>
          <Card
            title="Initial Closing Date"
            icon={"/img/dashboard/Shipments.svg"}
          >
            {offering?.initial_closing_date
              ? moment(offering?.initial_closing_date).format("LL")
              : "---"}
          </Card>
          <Card title="Termination Date" icon={"/img/dashboard/Shipments.svg"}>
            {offering?.termination_date
              ? moment(offering?.termination_date).format("LL")
              : "---"}
          </Card>
          <Card title="Extension Date" icon={"/img/dashboard/Shipments.svg"}>
            {offering?.extension_date
              ? moment(offering?.extension_date).format("LL")
              : "---"}
          </Card>
        </div>
        <div className="row">
          <div className="col col-12 col-md-8">
            <div className="card">
              <div className="card-body p-0 px-4">
                <div className="list-group list-group-flush">
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">Stock Ticker</div>
                      {offering?.stock_ticker ? (
                        <a
                          className="col text-end col-sm-auto"
                          href={`https://finance.yahoo.com/quote/${offering?.stock_ticker}/`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {offering?.stock_ticker}{" "}
                        </a>
                      ) : (
                        <span className="col text-end text-muted col-sm-auto">
                          N/A
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col col-12">
                        Company Business Description
                      </div>
                      <div className="col col-12 mt-2 col-sm-auto text-muted">
                        <ReadMore
                          text={offering?.description ?? "N/A"}
                          maxLength={500}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-6">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Exemptions</h4>
                  </div>
                  <div className="card-body p-0 px-4">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Primary exemption</div>
                          <div className="col col-sm-auto text-muted">
                            {offering?.exemption?.primary}
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Secondary exemption</div>
                          <div className="col col-sm-auto text-muted">
                            {offering?.exemption?.secondary}
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Tertiary exemption</div>
                          <div className="col col-sm-auto text-muted">
                            {offering?.exemption?.tertiary}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Subscription</h4>
                  </div>
                  <div className="card-body p-0 px-4">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">
                            <IconControl
                              placement="top"
                              iconName="fe fe-info ms-2"
                              inputTitle="Minimum Subscription Amount"
                              tooltipTitle="This is the minimum subscription amount you are willing to accept from a subscriber"
                            />
                          </div>
                          <div className="col text-end col-sm-auto text-muted">
                            {summary?.investor_minimum_amount && (
                              <NumberFormat
                                prefix="$"
                                value={summary?.investor_minimum_amount}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {(stockTypeName(offering?.stock_type)?.includes(
                        "common_stock"
                      ) ||
                        stockTypeName(offering?.stock_type)?.includes(
                          "preferred_stock"
                        )) && (
                        <div className="list-group-item">
                          <div className="row align-items-center">
                            <div className="col">Minimum Shares</div>
                            <div className="col text-end col-sm-auto text-muted">
                              {summary.investor_minimum_amount &&
                                offering.unit_price && (
                                  <NumberFormat
                                    value={
                                      summary.investor_minimum_amount /
                                      fromCurrencyNumber(offering.unit_price)
                                    }
                                    displayType="text"
                                    thousandSeparator={true}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <HStack>
                      <h4 className="card-header-title">
                        Authorized Signatory
                      </h4>
                      <Spacer />
                      <OfferingSignatoryButton offering={offering}>
                        {offering?.signatory ? "Change" : "Add"}
                      </OfferingSignatoryButton>
                    </HStack>
                  </div>
                  <div className="card-body p-0 px-4">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Name</div>
                          <div className="col text-end col-sm-auto text-muted">
                            {offering?.signatory
                              ? userFullName(offering?.signatory) ?? "N/A"
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Email</div>
                          <div className="col text-end col-sm-auto text-muted">
                            {offering?.signatory?.email ?? "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Title</div>
                          <div className="col text-end col-sm-auto text-muted">
                            {offering?.signatory?.title ?? "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Company</div>
                          <div className="col text-end col-sm-auto text-muted">
                            {offering?.signatory?.company ?? "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='col col-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h4 className='card-header-title'> Add Ons</h4>
                  </div>
                  <div className='card-body p-0 px-4'>
                    <div className='list-group list-group-flush'>
                      <div className='list-group-item'>
                        <div className='row'>
                          <div className='col'>

                          </div>
                          <div className='col col-auto'>
                            <div className='form-check form-switch'>
                              <input className='form-check-input' style={{ cursor: 'not-allowed' }} type='checkbox' id='subscription_privco_link' value={offering?.subscription?.borrow_against_your_shares} readOnly checked={offering?.subscription?.borrow_against_your_shares ? offering?.subscription?.borrow_against_your_shares : false} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='list-group-item'>
                        <div className='row'>
                          <div className='col'>
                            <label className=''>Investor Relations</label>
                          </div>
                          <div className='col col-auto'>
                            <div className='form-check form-switch'>
                              <input className='form-check-input' style={{ cursor: 'not-allowed' }} type='checkbox' id='subscription_privco_link' value={offering?.subscription?.investor_relations_firm} readOnly checked={offering?.subscription?.investor_relations_firm ? offering?.subscription?.investor_relations_firm : false} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='list-group-item'>
                        <div className='row'>
                          <div className='col'>
                            <label className=''>Transfer Agent</label>
                          </div>
                          <div className='col col-auto'>
                            <div className='form-check form-switch'>
                              <input className='form-check-input' style={{ cursor: 'not-allowed' }} type='checkbox' id='subscription_privco_link' readOnly value={offering?.subscription?.transfer_agent} checked={offering?.subscription?.transfer_agent ? offering?.subscription?.transfer_agent : false} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='list-group-item'>
                        <div className='row'>
                          <div className='col'>
                            <label className=''>Financial Printer</label>
                          </div>
                          <div className='col col-auto'>
                            <div className='form-check form-switch'>
                              <input className='form-check-input' style={{ cursor: 'not-allowed' }} type='checkbox' id='subscription_privco_link' readOnly value={offering?.subscription?.financial_printer} checked={offering?.subscription?.financial_printer ? offering?.subscription?.financial_printer : false} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">Offering</h4>
                {/* <Link to={`/offering/${offering.id}/edit`}><i className='fe fe-edit me-1' /></Link> */}
              </div>
              <div className="card-body p-0 px-4">
                <div className="list-group list-group-flush">
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">Security type</div>
                      <div className="col col-auto text-muted">
                        {stockTypeName(offering?.stock_type)}
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">Total Offering Amount</div>
                      <div className="col col-auto text-muted">
                        {offering?.total_amount && (
                          <NumberFormat
                            prefix="$"
                            decimalScale={0}
                            value={offering?.total_amount}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {offering?.additional_info?.unit_sub_type1 ===
                    "unit_convertable_notes" && (
                    <>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Sub type 1</div>
                          <div className="col col-auto text-muted">
                            Convertible Notes
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {(offering.stock_type === "convertible_note" ||
                    offering.stock_type ===
                      "In lieu of cash with Convertible Notes" ||
                    offering.stock_type?.includes(
                      "Unit with convertible notes"
                    )) && (
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col">Original Issuance Discount</div>
                        <div className="col col-auto text-muted">
                          ${offering?.additional_info?.oic_amount}
                        </div>
                      </div>
                    </div>
                  )}
                  {(offering.stock_type === "convertible_note" ||
                    offering.stock_type ===
                      "In lieu of cash with Convertible Notes" ||
                    offering.stock_type?.includes(
                      "Unit with convertible notes"
                    )) && (
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col">Original Issuance Discount</div>
                        <div className="col col-auto text-muted">
                          {offering?.additional_info?.oic_percentage}%
                        </div>
                      </div>
                    </div>
                  )}
                  {(offering.stock_type === "convertible_note" ||
                    offering.stock_type ===
                      "In lieu of cash with Convertible Notes" ||
                    offering.stock_type?.includes(
                      "Unit with convertible notes"
                    )) && (
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col">Original Principal Amount</div>
                        <div className="col col-auto text-muted">
                          {offering?.additional_info
                            ?.original_principle_amount && (
                            <NumberFormat
                              prefix="$"
                              decimalScale={0}
                              value={
                                offering?.additional_info
                                  ?.original_principle_amount
                              }
                              displayType="text"
                              thousandSeparator={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {(offering.stock_type === "convertible_note" ||
                    offering.stock_type ===
                      "In lieu of cash with Convertible Notes" ||
                    offering.stock_type?.includes(
                      "Unit with convertible notes"
                    )) && (
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col">Annual Interest Rate</div>
                        <div className="col col-auto text-muted">
                          {offering?.additional_info?.annual_rate}%
                        </div>
                      </div>
                    </div>
                  )}
                  {(offering.stock_type === "convertible_note" ||
                    offering.stock_type ===
                      "In lieu of cash with Convertible Notes" ||
                    offering.stock_type?.includes(
                      "Unit with convertible notes"
                    )) && (
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col">Default Interest Rate</div>
                        <div className="col col-auto text-muted">
                          {offering?.additional_info?.default_rate}%
                        </div>
                      </div>
                    </div>
                  )}
                  {(offering.stock_type === "convertible_note" ||
                    offering.stock_type ===
                      "In lieu of cash with Convertible Notes" ||
                    offering.stock_type?.includes(
                      "Unit with convertible notes"
                    )) &&
                    offering?.additional_info?.enter_maturity_date && (
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Maturity Date</div>
                          <div className="col col-auto text-muted">
                            {moment(
                              offering?.additional_info?.maturity_date
                            ).format("LL")}
                          </div>
                        </div>
                      </div>
                    )}
                  {(offering.stock_type === "convertible_note" ||
                    offering.stock_type ===
                      "In lieu of cash with Convertible Notes" ||
                    offering.stock_type?.includes(
                      "Unit with convertible notes"
                    )) &&
                    offering?.additional_info?.term !== 0 &&
                    !offering?.additional_info?.enter_maturity_date && (
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Term (in months)</div>
                          <div className="col col-auto text-muted">
                            {offering?.additional_info?.term}
                          </div>
                        </div>
                      </div>
                    )}
                  {(offering.stock_type === "convertible_note" ||
                    offering.stock_type ===
                      "In lieu of cash with Convertible Notes" ||
                    offering.stock_type?.includes(
                      "Unit with convertible notes"
                    )) && (
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col">Conversion Price</div>
                        <div className="col col-auto text-muted">
                          {offering?.unit_price === "0.00"
                            ? `As Per Note`
                            : `$${offering?.unit_price}`}
                        </div>
                      </div>
                    </div>
                  )}
                  {(offering.stock_type === "convertible_note" ||
                    offering.stock_type ===
                      "In lieu of cash with Convertible Notes" ||
                    offering.stock_type?.includes(
                      "Unit with convertible notes"
                    )) && (
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col">
                          Beneficial Ownership Limitation
                        </div>
                        {offering?.additional_info?.blocker?.value ===
                        "other" ? (
                          <div className="col col-auto text-muted">0</div>
                        ) : (
                          <div className="col col-auto text-muted">
                            {offering?.additional_info?.blocker?.value}%
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(stockTypeOfferingPage(offering?.stock_type)?.includes(
                    "Common"
                  ) ||
                    stockTypeOfferingPage(offering?.stock_type)?.includes(
                      "Preferred"
                    ) ||
                    offering.stock_type?.includes(
                      "In lieu of cash with common stock"
                    ) ||
                    offering?.stock_type?.includes(
                      "Unit with common stock"
                    )) && (
                    <div className="list-group-item" key={3}>
                      <div className="row align-items-center">
                        {!offering?.stock_type?.includes(
                          "Unit with common stock"
                        ) ? (
                          <div className="col">Number of shares</div>
                        ) : (
                          <div className="col">Number of units</div>
                        )}
                        <div className="col col-sm-auto text-muted">
                          {offering?.total_units && (
                            <NumberFormat
                              value={offering?.total_units}
                              displayType="text"
                              thousandSeparator={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {(stockTypeOfferingPage(offering?.stock_type)?.includes(
                    "Common"
                  ) ||
                    stockTypeOfferingPage(offering?.stock_type)?.includes(
                      "Preferred"
                    ) ||
                    offering.stock_type?.includes(
                      "In lieu of cash with common stock"
                    ) ||
                    offering?.stock_type?.includes("Unit with common stock") ||
                    offering.stock_type?.includes(
                      "In lieu of cash with common stock"
                    )) && (
                    <div className="list-group-item" key={4}>
                      <div className="row align-items-center">
                        {!offering?.stock_type?.includes(
                          "Unit with common stock"
                        ) ? (
                          <div className="col">Price per share</div>
                        ) : (
                          <div className="col">Price per unit</div>
                        )}
                        <div className="col col-sm-auto text-muted">
                          {offering?.unit_price && (
                            <NumberFormat
                              prefix={"$"}
                              decimalScale={2}
                              value={offering?.unit_price}
                              displayType="text"
                              thousandSeparator
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {offering?.additional_info?.unit_sub_type1 ===
                    "unit_common_stock" && (
                    <>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Sub type 1</div>
                          <div className="col col-sm-auto text-muted">
                            Common stock
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Quantity of share per unit</div>
                          <div className="col col-sm-auto text-muted">
                            {
                              offering?.additional_info
                                ?.units_common_stock_share
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {offering?.additional_info?.unit_sub_type2 ===
                    "with_warrants" && (
                    <>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Sub type 2</div>
                          <div className="col col-sm-auto text-muted">
                            Warrants
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Percent</div>
                          <div className="col col-sm-auto text-muted">
                            {offering?.additional_info?.units_warrants_percent}%
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Exercise Price</div>
                          <div className="col col-auto text-muted">
                            {CurrencyFormatter.format(
                              offering?.additional_info?.unit_exercise_price ??
                                0
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Term (in months)</div>
                          <div className="col col-auto text-muted">
                            {offering?.additional_info?.term}
                          </div>
                        </div>
                      </div>
                      {hasExercisePrice2 && (
                        <>
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">Exercise Price 2</div>
                              <div className="col col-auto text-muted">
                                {CurrencyFormatter.format(
                                  offering?.additional_info
                                    ?.unit_exercise_price_2 ?? 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">Term 2 (in months)</div>
                              <div className="col col-auto text-muted">
                                {offering?.additional_info?.term_2}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {offering?.additional_info?.unit_sub_type1 ===
                    "unit_preferred_stock" && (
                    <>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Sub type</div>
                          <div className="col col-auto text-muted">
                            Preferred stock
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Quantity of share per unit</div>
                          <div className="col col-auto text-muted">
                            {
                              offering?.additional_info
                                ?.units_preferred_stock_shares
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {offering?.additional_info?.unit_sub_type2 ===
                    "unit_preferred_stock" && (
                    <>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Sub type 2</div>
                          <div className="col col-auto text-muted">
                            Preferred stock
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">Quantity of share per unit</div>
                          <div className="col col-auto text-muted">
                            {offering?.additional_info
                              ?.units_preferred_stock_shares_2 ??
                              offering?.additional_info
                                ?.units_preferred_stock_shares}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">Additional Info</h4>
              </div>
              <div className="card-body p-0 px-4">
                <div className="list-group list-group-flush">
                  <div className="list-group-item" key={1}>
                    <div className="row align-items-center">
                      <div className="col">
                        <IconControl
                          inputTitle="Minimum Offering Amount"
                          placement="top"
                          iconName="fe fe-info ms-2"
                          tooltipTitle="The minimum offering amount in USD the issuer is able to accept and close the offering"
                        />
                      </div>
                      <div sm="auto" className=" col col-auto text-muted">
                        {summary?.minimum_offering_amount && (
                          <NumberFormat
                            prefix="$"
                            value={summary?.minimum_offering_amount}
                            displayType="text"
                            thousandSeparator
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {(stockTypeOfferingPage(offering?.stock_type)?.includes(
                    "Common"
                  ) ||
                    stockTypeOfferingPage(offering?.stock_type)?.includes(
                      "Preferred"
                    ) ||
                    offering.stock_type?.includes(
                      "In lieu of cash with common stock"
                    ) ||
                    offering?.stock_type?.includes(
                      "Unit with common stock"
                    )) && (
                    <div className="list-group-item" key={2}>
                      <div className="row align-items-center">
                        <div className="col">Minimum Shares</div>
                        <div className="col col-auto text-muted">
                          {summary?.minimum_offering_amount &&
                            offering?.unit_price && (
                              <NumberFormat
                                value={
                                  summary?.minimum_offering_amount /
                                  fromCurrencyNumber(offering?.unit_price)
                                }
                                displayType="text"
                                thousandSeparator={true}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="list-group-item" key={3}>
                    <div className="row align-items-center">
                      <div className="col">Optional Maximum</div>
                      <NumberFormat
                        className="col col-auto text-muted"
                        value={summary.optional_maximum_percent}
                        displayType="text"
                        thousandSeparator
                        suffix="%"
                      />
                    </div>
                  </div>
                  {(stockTypeOfferingPage(offering?.stock_type)?.includes(
                    "Common"
                  ) ||
                    stockTypeOfferingPage(offering?.stock_type)?.includes(
                      "Preferred"
                    ) ||
                    offering.stock_type?.includes(
                      "In lieu of cash with common stock"
                    ) ||
                    offering?.stock_type?.includes(
                      "Unit with common stock"
                    )) && (
                    <div className="list-group-item" key={4}>
                      <div className="row align-items-center">
                        <div className="col">Optional Maximum Shares</div>
                        <div className="col col-auto text-muted">
                          {summary?.optional_maximum_percent &&
                            offering?.total_units && (
                              <NumberFormat
                                value={
                                  (summary?.optional_maximum_percent *
                                    offering?.total_units) /
                                  100
                                }
                                displayType="text"
                                thousandSeparator={true}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
