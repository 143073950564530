/* eslint-disable react-hooks/exhaustive-deps */
import
  {
    Button,
    Flex,
    Heading,
    Link,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
  } from "@chakra-ui/react";
import FileSaver from "file-saver";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getEvent } from "@equidefi/portals/helpers/investments";
import { Paper } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import OfferingClient from "../../clients/OfferingClient";
import TrauncheInvestmentModal from "../../components/offerings/TrauncheInvestmentModal";
import Spinner from "../../components/spinner";
import { STOCK_TYPES } from "../../constants";
import { CurrencyFormatter, NumberFormatter } from "../../constants/format";
import { useOfferingTranche } from "../../hooks/useOfferings";
import Header from "../../layouts/header";
import
  {
    DateTimeService,
    MomentDateTimeAdapter,
  } from "../../services/DateTimeService";

const dateFormat = new DateTimeService(new MomentDateTimeAdapter());

const CertificateSummaryDataPoint = ({ label, value }) => {
  return (
    <li className="list-group-item px-4 d-flex justify-content-between w-100 align-items-center">
      <strong style={{ maxWidth: "75%" }}>{label}</strong>
      <span>{value}</span>
    </li>
  );
};

const OfferingTranche = () => {
  const { trauncheId: id, id: offeringId } = useParams();
  const { data: tranche, isLoading } = useOfferingTranche(id, offeringId);
  const { offering, issuer, investments, summary } = tranche ?? {};

  const [tailSpinner, showTailSpinner] = useState(false);
  const [formDSpinner, showFormDSpinner] = useState(false);
  const [printSpinner, setPrintSpinner] = useState(false);
  const [currentInvestor, setCurrentInvestor] = useState(null);

  const downloadFile = async (documentType, filename) => {
    try {
      const data = await OfferingClient.getTrauncheDocument(
        id,
        offeringId,
        documentType
      );
      FileSaver.saveAs(`data:application/pdf;base64,${data.data}`, filename);
    } catch (e) {
      toast.error(`Sorry, we are having trouble downloading ${filename}`);
    }
  };

  const downloadFormD = async () => {
    showFormDSpinner(true);
    await downloadFile("formd", `Offering Form D - ${offering.name}.pdf`);
    showFormDSpinner(false);
  };

  const downloadTail = async () => {
    showTailSpinner(true);
    await downloadFile("tail", `Offering TAIL - ${offering.name}.pdf`);
    showTailSpinner(false);
  };

  const printCertificate = async () => {
    setPrintSpinner(true);
    await downloadFile(
      "certificate",
      `Closing Tranche Summary - ${offering.name}.pdf`
    );
    setPrintSpinner(false);
  };

  if (isLoading) {
    return <Spinner show />;
  }

  return (
    <>
      <Header
        name="Closing Tranche Summary"
        backTo={[
          `/offering/${offeringId}/tranches`,
          "Back to Closing Tranches",
        ]}
        title={offering?.name}
      >
        <Button
          isLoading={printSpinner}
          onClick={printCertificate}
          disabled={!investments?.length}
        >
          Print Certificate
        </Button>
      </Header>

      <div className="container-fluid">
        <div className="row">
          <h3>Summary</h3>
          <div className="col-12 col-lg-6">
            <div className="card">
              <ul className="list-group list-group-flush">
                <CertificateSummaryDataPoint
                  label="Issuer Name"
                  value={issuer?.name}
                />
                {/* <CertificateSummaryDataPoint
                  label="Offering Name"
                  value={offering?.name}
                /> */}
                <CertificateSummaryDataPoint
                  label="Launch Date"
                  value={dateFormat.formatMonthDayYear(offering?.launch_date)}
                />
                <CertificateSummaryDataPoint
                  label="Closing Date"
                  value={dateFormat.formatMonthDayYear(summary?.closing_date)}
                />
                <CertificateSummaryDataPoint
                  label="Total Closing Amount"
                  value={CurrencyFormatter.format(
                    summary?.total_closing_amount
                  )}
                />
                <CertificateSummaryDataPoint
                  label={`Total ${
                    STOCK_TYPES[offering?.stock_type] ?? offering?.stock_type
                  } Sold`}
                  value={NumberFormatter.format(
                    summary.total_security_type_sold
                  )}
                />
                <CertificateSummaryDataPoint
                  label="Number of Investors"
                  value={investments?.length ?? 0}
                />
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-header-title">Documents</h3>
              </div>
              <div className="card-body p-0">
                <ul className="list-group list-group-flush">
                  <CertificateSummaryDataPoint
                    label="Transfer Agent Instruction Letter (TAIL)"
                    value={
                      <Button
                        isLoading={tailSpinner}
                        colorScheme="gray"
                        size="xs"
                        onClick={() => downloadTail()}
                      >
                        Download
                      </Button>
                    }
                  />
                  <CertificateSummaryDataPoint
                    label="Form D"
                    value={
                      <Button
                        isLoading={formDSpinner}
                        colorScheme="gray"
                        size="xs"
                        onClick={() => downloadFormD()}
                      >
                        Download
                      </Button>
                    }
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>

        <hr style={{ pageBreakAfter: "always" }} />

        <Heading as="h3" size="md">
          Closed Investors
        </Heading>
        <Paper>
          <TableContainer>
            <Table size="lg" variant="simple" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Accredited</Th>
                  <Th>Investment Amount</Th>
                  <Th>Unit Quantity</Th>
                  <Th>Fund Method</Th>
                  <Th>Funding Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {investments?.map((investment) => {
                  return (
                    <Tr
                      cursor="pointer"
                      onClick={() => setCurrentInvestor(investment)}
                      key={investment.id}
                    >
                      <Td verticalAlign="middle">
                        <Text mb={0}>{investment.user.full_name}</Text>
                        <Flex color="gray.400" align="center" gap={2}>
                          <Icon.Mail size=".85em" />
                          <Link
                            href={`mailto:${investment.user.email}`}
                            rel="external noreferrer noopener"
                            target="_blank"
                            textDecor="underline"
                          >
                            {investment.user.email}
                          </Link>
                        </Flex>
                      </Td>
                      <Td verticalAlign="middle">
                        {investment.bad_actor ? "No" : "Yes"}
                      </Td>
                      <Td verticalAlign="middle" isNumeric>
                        {CurrencyFormatter.format(investment.amount)}
                      </Td>
                      <Td verticalAlign="middle" isNumeric>
                        {NumberFormatter.format(investment.quantity)}
                      </Td>
                      <Td verticalAlign="middle">
                        {investment.is_manual_payment ? "Wire/Check" : "Stripe"}
                      </Td>
                      <Td verticalAlign="middle">
                        {dateFormat.formatReadableTimestamp(
                          getEvent(
                            investment.event_history,
                            "PAYMENT",
                            "APPROVED"
                          )?.create_date
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <TrauncheInvestmentModal
        investment={currentInvestor}
        onHide={() => setCurrentInvestor(null)}
      />
    </>
  );
};

export default OfferingTranche;
