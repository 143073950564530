/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Tag, Tooltip, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import FileSaver from "file-saver";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import Swal from "sweetalert";

import { InvestmentEventHistory } from "@equidefi/portals/components/investments/EventHistory";
import { StatusBadge } from "@equidefi/portals/components/investments/StatusBadge";
import { hasEvent } from "@equidefi/portals/helpers/investments";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { offeringIsRegA, userFullName } from "@equidefi/shared";
import { COMPLETED } from "@equidefi/shared/constants/investments";
import { Icon } from "@equidefi/ui/icon";

import InfoCard from "../../../components/card";
import InvestmentAgreements from "../../../components/investments/Agreements/Agreements";
import InvestmentSignatures from "../../../components/investments/Agreements/InvestmentSignatures";
import InvestmentAccreditation from "../../../components/investments/InvestmentAccreditation/InvestmentAccreditation";
import InvestmentInquiry from "../../../components/investments/InvestmentInquiry";
import InvestmentPayments from "../../../components/investments/InvestmentPayments";
import ModalUpdateStatus from "../../../components/investments/modals/UpdateStatus";
import ModalUpdateWorkflowStep from "../../../components/investments/modals/UpdateWorkflowStep";
import Spinner from "../../../components/spinner";
import
  {
    getSettingById,
    getSettingsByOfferingId,
    isAdmin,
    isIssuerEditor,
    isIssuerOwner,
    isOfferingEditor,
    isOfferingOwner,
  } from "../../../helpers";
import
  {
    useInvestment,
    useInvestmentAgreementRecipients,
  } from "../../../hooks/investments";
import { useOfferingFull } from "../../../hooks/useOfferings";
import Header from "../../../layouts/header";

const NO_POKE_INVESTOR = ["APPROVED", "COMPLETED"];

const InvestmentOverview = (props) => {
  const { id, investment_id } = useParams();
  const { data: full } = useOfferingFull(id);
  const { data: user } = useCurrentUser();

  const componentRef = useRef();

  const { data: investmentData, refetch: refetchInvestment } =
    useInvestment(investment_id);
  const { data: agreements } = useInvestmentAgreementRecipients(investment_id);
  const updateStatusModal = useDisclosure(false);
  const updateWorkflowStep = useDisclosure(false);
  const [spinner, showSpinner] = useState(false);
  const [modalInvestorReview, showInvestorReviewModal] = useState(false);
  const [modalInvestmentEditModal, showInvestmentEditModal] = useState(false);

  const [editInvestment, setEditInvestment] = useState(false);
  const [reviewDescription, setReviewDescription] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState();

  const canEdit = useMemo(
    () =>
      isAdmin(user) ||
      isOfferingEditor(user, full?.offering?.id) ||
      isOfferingOwner(user, full?.offering?.id),
    [user, full]
  );

  const paid = useMemo(() => {
    let value = 0;
    investmentData?.stripe_payments?.forEach((payment) => {
      if (payment?.status?.includes("succeeded"))
        value += Number(payment.amount);
    });
    investmentData?.payments?.forEach((payment) => {
      if (payment?.status?.includes("succeeded"))
        value += Number(payment.amount);
    });
    return value;
  }, [investmentData]);

  const balance = useMemo(() => {
    if (!investmentData) return 0;

    if (investmentData?.approved_amount) {
      return Number(investmentData?.approved_amount) - paid;
    }

    return Number(investmentData?.amount) - paid;
  }, [investmentData, paid]);

  const isRegA = useMemo(() => offeringIsRegA(full?.offering), [full]);

  const canCountersign = useMemo(() => {
    return (
      balance === 0 &&
      investmentData?.status !== "REJECTED" &&
      hasEvent(investmentData?.event_history, "PAYMENT", "APPROVED") &&
      (isRegA
        ? true
        : hasEvent(investmentData?.event_history, "DILIGENCE", "APPROVED"))
    );
  }, [investmentData?.status, investmentData?.event_history, balance, full]);

  const investorSignedAllAgreements = useMemo(() => {
    const investorCompletedCount =
      agreements?.filter(
        (agreement) =>
          agreement.recipients.signers
            ?.filter((s) => s.roleName !== "issuer")
            ?.filter((s) => s.status === "completed")?.length > 0
      )?.length ?? 0;

    return investorCompletedCount === agreements?.length;
  }, [agreements]);

  const isClosable = useMemo(() => {
    return (
      agreements?.length > 0 &&
      hasEvent(investmentData?.event_history, "COUNTERSIGN", "COMPLETED") &&
      !hasEvent(investmentData?.event_history, "CLOSING", "COMPLETED") &&
      paid === Number(investmentData?.approved_amount || investmentData?.amount)
    );
  }, [investmentData, agreements, paid]);

  const canCloseInvestment = useMemo(
    () => isClosable && canEdit && investorSignedAllAgreements,
    [isClosable, canEdit, investmentData, paid, investorSignedAllAgreements]
  );

  useEffect(() => {
    if (investmentData) {
      setInvestmentAmount(
        Number(investmentData.approved_amount || investmentData.amount)
      );
    }
  }, [investmentData]);

  useEffect(() => {
    if (investmentData && modalInvestmentEditModal === false) {
      setInvestmentAmount(
        Number(investmentData.approved_amount || investmentData.amount)
      );
    }
  }, [investmentData, modalInvestmentEditModal]);

  // Download Questionnaire
  const handlePdfDownload = () => {
    showSpinner(true);
    axios.get(`investment/${investment_id}/questionnaire`).then((response) => {
      let toBytes = new Uint8Array(response.data.data),
        toBinary = ``;
      toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));
      const file = window.btoa(toBinary);
      FileSaver.saveAs(
        `data:application/pdf;base64,${file}`,
        "investor_questionnaire.pdf"
      );
      showSpinner(false);
    });
  };

  // Print Questionnaire
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const isAuthorizedSignatory = useMemo(
    () =>
      full?.offering?.signatory &&
      full?.offering?.signatory?.email === user?.email,
    [user?.email]
  );

  const handleInvestorApprove = () => {
    showSpinner(true);

    let data = {
      description: reviewDescription,
    };

    axios
      .post(`/investment/${investment_id}/status/issuer_approved`, data)
      .then((response) => {
        refetchInvestment();
        showSpinner(false);
        showInvestorReviewModal(false);
        toast.success("Investor has been Approved");
      });
  };

  const handleInvestorReject = () => {
    showSpinner(true);

    let data = {
      description: reviewDescription,
    };

    axios
      .post(`/investment/${investment_id}/status/issuer_rejected`, data)
      .then((response) => {
        refetchInvestment();
        showSpinner(false);
        showInvestorReviewModal(false);
        toast.success("Investor has been Rejected");
      });
  };

  const handleResend = () => {
    showSpinner(true);
    axios.post(`/investment/${investment_id}/notification`).then((response) => {
      toast.success("Email has been sent again");
      showSpinner(false);
    });
  };

  const handleAcceptAll = () => {
    Swal({
      title: `Accept Investments?`,
      text: `You will be redirected to DocuSign to countersign the pending investments, do you want to continue?`,
      buttons: ["Cancel", "Yes"],
      icon: "success",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        let thread = 0;
        const agreement = investmentData?.agreements?.filter(
          (x) =>
            x.investor_status === "complete" && x.issuer_status !== "complete"
        );
        if (agreement?.length > 0) {
          thread++;
          axios
            .get(
              `investment/${investment_id}/agreement/${agreement[0].envelope_id}/url/issuer`
            )
            .then((response) => {
              window.open(response.data.url, "_blank");
              thread--;
              if (thread === 0) showSpinner(false);
            })
            .catch((error) => {
              console.log(error);
              thread--;
              if (thread === 0) showSpinner(false);
            });
        }
      }
    });
  };

  const downloadClosingDocument = async () => {
    const response = await axios.get(`investment/${investment_id}/closing`);

    const toBytes = new Uint8Array(response.data.data);
    const toBinary = toBytes.reduce(
      (memo, res) => (memo += String.fromCharCode(res)),
      ``
    );
    const file = window.btoa(toBinary);

    FileSaver.saveAs(
      `data:application/pdf;base64,${file}`,
      "transfer_agent_letter.pdf"
    );
  };

  const handleCloseAll = () => {
    Swal({
      title: `Close Investments?`,
      text: `The closing letter for all accepted investors will be sent to the Transer Agent for closing, do you want to continue?`,
      buttons: ["Cancel", "Yes"],
      icon: "success",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        const total = investmentAmount;
        getSettingsByOfferingId(id).then((settings) => {
          const bankType = getSettingById(settings, "payment_bank_type");
          if (
            bankType.toLowerCase() === "escrow" ||
            bankType.toLowerCase() === "escrow est"
          ) {
            toast.success(
              `The funds in the amount of $${total.toLocaleString()} will be wired to your bank account within 24 hours.`
            );
          } else {
            toast.success(`Investment closed successfully.`);
          }
        });
        const body = [investment_id];
        axios.post(`investment/close`, body).then((response) => {
          investmentData.status = COMPLETED;
          refetchInvestment();
          showSpinner(false);
        });
      }
    });
  };

  const handleEdit = (value) => {
    setInvestmentAmount(value);
  };

  const saveEditedAmount = async () => {
    investmentData.approved_amount = investmentAmount;
    investmentData.approved_quantity = investmentAmount / full.unit_price;
    investmentData.edited = true;
    showSpinner(true);
    showInvestmentEditModal(false);
    if (investmentData?.edited)
      await axios.post(
        `investment/${investment_id}/approved_quantity/${investmentData?.approved_quantity}`
      );
    else if (investmentData?.approved_quantity === undefined)
      await axios.post(
        `investment/${investment_id}/approved_quantity/${investmentData?.quantity}`
      );
    setEditInvestment(false);
    showSpinner(false);
  };

  const canEditInvestmentAmount = useMemo(() => {
    return (
      isAuthorizedSignatory &&
      (hasEvent(investmentData?.event_history, "PAYMENT", "SUCCESSFUL") ||
        hasEvent(investmentData?.event_history, "AGREEMENT", "SUBMITTED")) &&
      (isIssuerOwner(user, investmentData?.issuer_id) ||
        isAdmin(user) ||
        isIssuerEditor(user, investmentData?.issuer_id))
    );
  }, [isAuthorizedSignatory, investmentData, user]);

  return (
    <>
      <Header
        id={id}
        title={investmentData?.offering?.name}
        name={userFullName(investmentData?.user)}
      >
        {isClosable && (
          <Tooltip
            hasArrow
            placement="auto-start"
            label={
              canCloseInvestment ? (
                <>Click here to close the investment</>
              ) : (
                <>
                  Investment can be closed only when all parties have signed the
                  agreement
                </>
              )
            }
          >
            <Button
              leftIcon={<Icon.Info size="1em" />}
              isDisabled={!canCloseInvestment}
              aria-disabled={!canCloseInvestment}
              onClick={handleCloseAll}
            >
              Close Investor
            </Button>
          </Tooltip>
        )}
      </Header>
      <div className="container-fluid">
        <Spinner show={spinner}>
          <div className="row">
            <InfoCard
              editable={
                isIssuerOwner(user, investmentData?.issuer_id) ||
                isOfferingOwner(user, investmentData?.offering_id) ||
                isAdmin(user)
              }
              onClick={updateStatusModal.onOpen}
              title="Status"
            >
              <StatusBadge
                as={Tag}
                fontSize="lg"
                action={investmentData?.status}
              />
              <ModalUpdateStatus
                investmentId={investment_id}
                onHide={() => updateStatusModal.onClose()}
                onSubmit={() => {
                  updateStatusModal.onClose();
                  refetchInvestment();
                }}
                show={updateStatusModal.isOpen}
              />
            </InfoCard>
            <InfoCard
              editable={
                isIssuerOwner(user, investmentData?.issuer_id) ||
                isOfferingOwner(user, investmentData?.offering_id) ||
                isAdmin(user)
              }
              onClick={updateWorkflowStep.onOpen}
              title="Investment Current Step"
            >
              <Tag fontSize="lg" textTransform="uppercase" colorScheme="gray">
                {investmentData?.next ?? "None"}
              </Tag>
              <ModalUpdateWorkflowStep
                investmentId={investment_id}
                onHide={() => updateWorkflowStep.onClose()}
                onSubmit={() => {
                  updateWorkflowStep.onClose();
                  refetchInvestment();
                }}
                show={updateWorkflowStep.isOpen}
              />
            </InfoCard>
            <InfoCard title="Total Subscription">
              {investmentData?.approved_quantity &&
              investmentData?.approved_quantity !== investmentData?.quantity ? (
                <>
                  <span className="me-2">
                    <NumberFormat
                      value={investmentData?.approved_quantity}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </span>
                  <del className="small text-muted">
                    <NumberFormat
                      value={investmentData?.quantity}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </del>
                </>
              ) : (
                <NumberFormat
                  value={investmentData?.quantity}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={0}
                />
              )}
            </InfoCard>
            <InfoCard
              title="Investment Amount"
              editable={canEditInvestmentAmount}
              onClick={() => showInvestmentEditModal(true)}
            >
              {investmentData?.approved_amount &&
              investmentData?.amount !== investmentData?.approved_amount &&
              !editInvestment ? (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="me-2">
                        <NumberFormat
                          value={investmentData?.approved_amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix="$"
                        />
                      </span>
                      <del className="small text-muted">
                        <NumberFormat
                          value={investmentData?.amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix="$"
                        />
                      </del>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between">
                  <NumberFormat
                    value={investmentData?.amount}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={0}
                    prefix="$"
                  />
                </div>
              )}
            </InfoCard>
            <InfoCard title="Total Payments">
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={paid}
                prefix="$"
              />
            </InfoCard>
            <InfoCard title="Total Due">
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={balance}
                prefix="$"
              />
            </InfoCard>
          </div>

          <div className="row">
            <div className="col">
              {investmentData?.agreements ? (
                <InvestmentAgreements
                  investment={investmentData}
                  canCountersign={canCountersign}
                  canView={canEdit}
                />
              ) : (
                <InvestmentSignatures
                  investment={investmentData}
                  isCountersignable={canCountersign}
                  onCountersign={refetchInvestment}
                />
              )}

              <InvestmentPayments
                canEdit={canEdit}
                investment={investmentData}
                offering={full?.offering}
                totalPaid={paid}
                totalBalance={balance}
                onUpdatePayments={() => {
                  refetchInvestment();
                }}
              />

              <InvestmentInquiry />
              <InvestmentAccreditation
                investment={investmentData}
                offering={full?.offering}
                user={user}
              />

              {investmentData?.question_response && (
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">
                      Investor Questionnaire
                    </h4>
                    <button
                      className="btn btn-sm btn-light"
                      onClick={() => showInvestorReviewModal(true)}
                    >
                      View
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="col col-md-4">
              <InvestmentEventHistory
                investment={investmentData}
                canPoke={
                  full?.offering?.status === "Active" &&
                  NO_POKE_INVESTOR.includes(investmentData?.status) &&
                  canEdit
                }
                onPoke={() => handleResend()}
              />
            </div>
          </div>
        </Spinner>
      </div>

      <Modal
        centered
        show={modalInvestmentEditModal}
        onHide={() => showInvestmentEditModal(false)}
      >
        <Modal.Header>Edit Investment Amount</Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <NumberFormat
              className="form-control"
              allowNegative={false}
              thousandSeparator={true}
              prefix="$"
              decimalScale={0}
              value={investmentAmount}
              onValueChange={({ value }) => handleEdit(value)}
              isAllowed={({ floatValue }) => {
                if (!floatValue) {
                  return true;
                }
                return floatValue <= investmentData.amount;
              }}
            />
          </div>
          <button className="btn btn-eq-primary" onClick={saveEditedAmount}>
            Save
          </button>
          <button
            className="btn btn-light ms-3"
            onClick={() => {
              showInvestmentEditModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        size="xl"
        show={modalInvestorReview}
        onHide={() => showInvestorReviewModal(false)}
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>Review Investor Questionnaire</span>{" "}
            {investmentData?.status !== "Invite Sent" && (
              <>
                <div>
                  {spinner ? (
                    <button className="btn btn-eq-primary me-3 ">
                      <span className="loading">Downloading...</span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-eq-primary me-3 "
                      onClick={handlePdfDownload}
                    >
                      Download
                    </button>
                  )}
                  <button className="btn btn-eq-primary" onClick={handlePrint}>
                    Print
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div ref={componentRef}>
            {investmentData?.question_response &&
              full?.offering?.questionnaire.map((section, index) => {
                return (
                  <div key={index}>
                    <h2 className="mb-0">
                      {section.title !== "Congrats! Almost finished!"
                        ? section.title
                        : ""}
                    </h2>
                    <h3 className="mb-0">{section.subtitle}</h3>
                    <div dangerouslySetInnerHTML={{ __html: section.text }} />
                    {section.questions.map((question, qIndex) => {
                      return (
                        <div key={qIndex}>
                          {investmentData?.question_response?.[question.id] && (
                            <label>
                              {investmentData?.question_response
                                .entity_individual === "entity" &&
                              question.id === "investor_name"
                                ? "Name of Investor"
                                : question.text}
                            </label>
                          )}

                          {question.type === "radio" && (
                            <>
                              {question.options?.map((option, index) => {
                                return (
                                  <div key={index}>
                                    <div className="form-check mb-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        defaultChecked={
                                          investmentData?.question_response?.[
                                            question.id
                                          ] === option.value
                                        }
                                        disabled
                                      />
                                      <label className="form-check-label ms-2">
                                        {option.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}

                          {question.type === "radio" &&
                            question.id !== "entity_individual" &&
                            investmentData?.question_response?.[question.id] ===
                              "yes" && (
                              <>
                                <div>
                                  <input
                                    type="text"
                                    name={question?.id}
                                    id={question?.id}
                                    className="form-control"
                                    placeholder={
                                      investmentData?.question_response[
                                        question.id.concat("_reason")
                                      ]
                                    }
                                    disabled
                                  />
                                </div>
                              </>
                            )}

                          {question.type === "checkbox" && (
                            <>
                              {question.options?.map((option, index) => {
                                return (
                                  <div key={index}>
                                    <div className="form-check mb-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        defaultChecked={
                                          investmentData?.question_response[
                                            option.id
                                          ] === true
                                        }
                                        disabled
                                      />
                                      <label className="form-check-label ms-2">
                                        {option.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}

                          {[
                            "address_line_1",
                            "address_city",
                            "address_state",
                            "address_postal_code",
                          ].includes(question.id) &&
                            investmentData?.question_response[question.id] && (
                              <p className="fw-bold">
                                {investmentData?.question_response[question.id]}
                              </p>
                            )}

                          {[
                            "text",
                            "ssn",
                            "ein",
                            "email",
                            "phone",
                            "zip",
                          ].includes(question.type) && (
                            <p className="fw-bold">
                              {investmentData?.question_response[question.id]}
                            </p>
                          )}
                        </div>
                      );
                    })}
                    {section.title === "Congrats! Almost finished!" ? (
                      <></>
                    ) : (
                      <hr />
                    )}
                  </div>
                );
              })}
          </div>

          {full?.offering?.status === "Active" &&
            hasEvent(investmentData?.event_history, "DILIGENCE", "SUBMITTED") &&
            !hasEvent(
              investmentData?.event_history,
              "DILIGENCE",
              "APPROVED"
            ) && (
              <Spinner show={spinner}>
                <div className="form-group">
                  <label className="mb-2">Description / Reason</label>
                  <textarea
                    className="form-control"
                    as="textarea"
                    rows="5"
                    name="description"
                    value={reviewDescription}
                    onChange={(e) => setReviewDescription(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn-eq-primary"
                  onClick={() => handleInvestorApprove()}
                >
                  Approve
                </button>
                <button
                  className="btn btn-light ms-3"
                  onClick={() => handleInvestorReject()}
                >
                  Reject
                </button>
              </Spinner>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestmentOverview;
